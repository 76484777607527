/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * $t('enum.E_AppUpdateType.Force')
 * $t('enum.E_AppUpdateType.Noraml')
 * $t('enum.E_AppUpdateType.Silent')
 * $t('enum.E_AppUpdateType.Ignorable')
 * $t('enum.E_AppUpdateType.SilentAndIgnorabl')
 */
export enum E_AppUpdateType {
    /**
     * 强制更新
     */
    Force = 0,
    /**
     * 一般更新
     */
    Noraml = 1,
    /**
     * 静默更新
     */
    Silent = 2,
    /**
     * 可忽略更新
     */
    Ignorable = 3,
    /**
     * 静默可忽略更新
     */
    SilentAndIgnorabl = 4
}

/**
 * $t('enum.E_AppVersionStatus.Unputaway')
 * $t('enum.E_AppVersionStatus.Putaway')
 */
export enum E_AppVersionStatus {
    /**
     * 未上架
     */
    Unputaway = 0,
    /**
     * 已上架
     */
    Putaway = 1,
}

export interface IAppSaveParams {
    /**
     * apk名称
     */
    appName: string
    /**
    * apk程序版本
    */
    appVersion: string
    /**
    * 文件Id
    */
    fileId: number
    /**
    * 更新描述
    */
    versionDescription: string
}


export type IAppListPackageParams = IPageParams & Partial<{
    startTime: string
    endTime: string
}>

export interface IAppListPackageItem {
    /**
     * apk名称
     */
    appId: string
    appName: string
    appVersion: string
    createTime: string
    fileUrl: string
    id: number
    updateType: E_AppUpdateType
    /**
     * 更新描述
     */
    versionDescription: string
    /**
     * 发布状态
     */
    versionStatus: E_AppVersionStatus
}

export type IAppListPackageData = IPageData<IAppListPackageItem>
