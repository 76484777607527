/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IOptionNumberItem, IOptionStringItem, IPageData, IPageParams } from "@/api/types"
import { E_UpkeepRepairHandleStatus } from "../types"

/**
 * 维修等级
 * $t('enum.E_RepairLevel.First')
 * $t('enum.E_RepairLevel.Second')
 * $t('enum.E_RepairLevel.Third')
 */
export enum E_RepairLevel {
    /**
     * 一级
     */
    First = 1,
    /**
     * 二级
     */
    Second = 2,
    /**
     * 三级
     */
    Third = 3,
}

/**
 * 故障影响
 * $t('enum.E_FaultEffect.Effect')
 * $t('enum.E_FaultEffect.NotEffect')
 */
export enum E_FaultEffect {
    /**
     * 影响设计产能
     */
    Effect = 1,
    /**
     * 不影响设计产能
     */
    NotEffect = 2,
}

export interface IRepairContent {
    repairContent: string
    repairLibSn: string
    repairName: string    
}

export interface IRepairItem {
    areaId: number
    companyId: number
    equipmentId: number
    /**
     * 维修内容
     */
    content: IRepairContent[]
    /**
     * 专业需求
     */
    demand: number[]
    /**
     * 安全措施
     */
    measure: number[]
    /**
     * 故障影响
     */
    faultEffect: E_FaultEffect
    /**
     * 故障部位
     */
    faultPosition: string
    remark: string
    repairLevel: E_RepairLevel
    /**
     * 维修名
     */
    repairName: string
    /**
     * 维修人
     */
    userId: number
}

export type IRepairAddParams = IRepairItem


export interface IRepairDetailParams {
    id: number
}

export interface IRepairHandle {
    assignTime: string
    file1: string
    file2: string
    file3: string
    id: number
    img1FileId: number
    img2FileId: number
    img3FileId: number

    handleDesc: string
    handleName: string
    handleTime: string
    repairId: number
    status: number
}

export interface IRepairDetailData {
    areaId: number
    areaName: string
    companyId: number
    companyName: string
    equipmentId: number
    equipmentName: string
    /**
     * 维修内容
     */
    content: (IRepairContent & { id: number })[]
    /**
     * 专业需求
     */
    demand: number[]
    /**
     * 安全措施
     */
    measure: number[]
    /**
     * 故障影响
     */
    faultEffect: E_FaultEffect
    /**
     * 故障部位
     */
    faultPosition: string
    remark: string
    repairLevel: E_RepairLevel
    /**
     * 维修名
     */
    repairName: string
    /**
     * 维修人
     */
    userId: number

    /**
     * 审核原因
     */
    examineReason: string
    file1: string
    file2: string
    file3: string
    id: number
    img1FileId: number
    img2FileId: number
    img3FileId: number
    repairUser: number
    repairUserName: string
    repairSn: string
    status: E_UpkeepRepairHandleStatus
    handle: IRepairHandle[]
    /**
     * 保修人
     */
    createName: string
    createdTime: string
}

export type IRepairListParams = IPageParams & Partial<{
    companyId: number
    areaId: number
    /**
     * 维修员
     */
    userId: number
}>

export interface IRepairListItem {
    areaName: string
    companyName: string
    createName: string
    createdTime: string
    id: number
    repairName: string
    repairSn: string
    repairUserName: string
    status: E_UpkeepRepairHandleStatus
    successTime: string
}

export type IRepairListData = IPageData<IRepairListItem>

export type IRepairDemandListData = IOptionNumberItem[]
export type IRepairMeasureListData = IOptionNumberItem[]