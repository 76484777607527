/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { API_BASE_URL } from './env'
/**
 * 全局常量、方法封装模块
 * 通过原型挂载到Vue属性
 * 通过 this.Global 调用
 */

// 后台管理系统服务器地址
let devBaseUrl = "http://192.168.0.141:9528"
devBaseUrl = "http://192.168.0.49:9528"
let demoBaseUrl = "http://192.168.0.24:9528";
let demoServerBaseUrl = 'http://192.168.0.24'
// let demoBaseUrl = "http://192.168.0.49:9528";
let relaseBaseUrl = "http://192.168.0.24:9527";
// let prodBaseUrl = "http://d.jjsensor.com/_api"
let prodBaseUrl = "/_api"

let prodServerBaseUrl = '/'

let baseUrl = API_BASE_URL || demoBaseUrl
let serverBaseUrl = demoServerBaseUrl
serverBaseUrl = prodServerBaseUrl
// baseUrl = devBaseUrl
baseUrl = prodBaseUrl
const IS_DEV = true

console.log("可以在localstorage设置`_baseUrl`指定本地api调试")

const debugBaseUrl = localStorage.getItem("_baseUrl");
if (debugBaseUrl) {
  baseUrl = debugBaseUrl;
  console.log(`%c由于localStorage设置了_baseUrl,使用测试地址: ${debugBaseUrl}`, 'color:red; font-size: 80px')
}
export { baseUrl, IS_DEV, serverBaseUrl };
