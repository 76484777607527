/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import NoData from "@/components/NoData/index.vue";
import { E_ChartTypeString } from '@/types';

@Component({
    name: 'Target',
    components: {
        NoData,
    }
})
export class TargetImpl extends Vue {
    @Prop({
        type: Array,
    }) checkList: E_ChartTypeString[] // 选中数据

    get Targets() {
        // 特征值图表类型
        let res = [
            E_ChartTypeString.ACCELERATION,
            E_ChartTypeString.VIBRATIO_INNTENSITY,
            E_ChartTypeString.DISPLACEMENT,
            E_ChartTypeString.TEMPERATURE,
            E_ChartTypeString.KURTOSIS,
            E_ChartTypeString.CREST_FACTOR,
            E_ChartTypeString.ENVELOPE_DEMODULATION,
            E_ChartTypeString.SPEED,
        ]
        return res
    }

    selected: E_ChartTypeString[] = []

    onClick(target: E_ChartTypeString) {
        if (this.hasSelectd(target)) {
            this.selected = _.filter(this.selected, s => s !== target)
        } else {
            this.selected = [...this.selected, target]
        }
    }

    hasSelectd(target: E_ChartTypeString) {
        return _.includes(this.selected, target)
    }

    targetName(target: E_ChartTypeString) {
        return this.$enumTrans.ChartTypeString(target)
    }

    cancel() {
        this.$emit("cancel", false);
    }

    confirm() {
        this.$emit("confirm", this.selected);
    }

    created() {
        this.selected = _.clone(this.checkList)
    }
}
