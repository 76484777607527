/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Vue, Component, Inject } from 'vue-property-decorator'
import { E_CollapseItemStatus } from '../types'
import { CollapseImpl } from '../CollapseImpl'
import { Events } from '@/utils/event'

@Component({
    name: 'CollapseItem'
})
export class CollapseItemImpl extends Vue {
    status = E_CollapseItemStatus.Normal

    get IsMinimum() {
        return this.status === E_CollapseItemStatus.Minimum
    }

    @Inject()
    collapse: CollapseImpl

    @Inject()
    registerCollapseItem: (item: CollapseItemImpl) => (() => void)

    onClickFullscreen() {
        this.collapse.clickCollapseItem(this)
    }

    created() {
        const handle = this.registerCollapseItem(this)
        Events.disposeOnComponent(this, () => {
            handle()
        })
    }
}
