/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Prop, Ref, Watch } from 'vue-property-decorator'
import * as echarts from 'echarts'
import {
    CollapseItem,
    CollapseArrow
} from '@/components/Collapse/index'
import { IOptionNumberItem } from '@/api/types'
import { IMappingItem } from '@/api/moudules/mapping.types'
import { isEmptyStringOrNil } from '@/utils/lang'
import { IQueryTemperatureAtlasParams } from '@/api/moudules/wavefrom.types'
import { formatDate, formatDateTime } from '@/utils/datetime'
import { temperature } from '@/charts/waveChartOption'
import { EChartImpl } from '@/components/EChart/EChartImpl'

function makeForm() {
    return {
        time: null,
        dataDensity: null,
        deviceId: null,
        mappingIds: [],
    }
}


export interface ITemperatureChartPanelOptions {
    title: string
    request: (params: any) => Promise<any>
}

@Component({
    name: 'TemperatureChartPanel',
    components: {
        CollapseArrow,
        CollapseItem,
    }
})
export class TemperatureChartPanelImpl extends Vue {
    @Prop({
        type: Object,
    }) options: ITemperatureChartPanelOptions

    @Prop({
        type: Number,
    }) areaId: number

    @Watch('areaId')
    onAreaIdChange(value: number) {
        this.form.deviceId = null
        this.equipmentOptions = []

        this.form.mappingIds = []
        this.mappingOptions = []
        this.fetchEquipments(value)
    }

    equipmentOptions: IOptionNumberItem[] = []
    fetchEquipments(areaId: number) {
        if (isEmptyStringOrNil(areaId)) {
            return
        }
        this.$api.equipment.getEquipmentListByAreaId(areaId).then((res) => {
            this.equipmentOptions = res.data
        })
    }

    onEquipmentChange(value: number) {
        this.form.mappingIds = []
        this.mappingOptions = []
        this.fetchMappings(value)
    }

    mappingOptions: IMappingItem[] = []
    fetchMappings(equipmentId: number) {
        if (isEmptyStringOrNil(equipmentId)) {
            return
        }
        this.$api.mapping.getMappingOptions(equipmentId).then(res => {
            this.mappingOptions = res.data
        })
    }

    densityOptions = _.map(_.range(1, 10), i => {
        return {
            label: this.$t("common.minutesTemplate", { value: i }),
            value: i
        }
    })

    form = makeForm()

    async requestFn(chart: echarts.ECharts) {
        const {
            time,
            dataDensity,
            deviceId,
            mappingIds,
        } = this.form
        if (_.isNil(deviceId)) {
            this.$message({
                type: 'error',
                message: this.$t('common.selectEquipment') as string,
            })
            return
        }
        if (_.isEmpty(mappingIds)) {
            this.$message({
                type: 'error',
                message: this.$t('common.selectMapping') as string,
            })
            return
        }
        if (!time) {
            this.$message({
                type: 'error',
                message: this.$t('common.selectDate') as string,
            })
            return
        }
        const params: IQueryTemperatureAtlasParams = {
            mappingIds: _.join(mappingIds, ','),
            deviceId,
            dataDensity,
            time: formatDate(time),
        }
        const res = await this.options.request(params)
        const legend = []; // 图例
        const series = []; // y轴数据
        _.each(res.data, (value, name) => {
            // 拼接图例
            legend.push(name);
            const o = {
                name: name,
                type: "line",
                data: _.map(value, x => {
                    return [formatDateTime(x.x), x.y]
                }),
                connectNulls: true,
            };
            series.push(o);
        })
        const title = this.options.title
        const option = temperature(this, title, legend, [], series);
        console.log(option)
        chart.setOption(option, true, true)
    }

    onSearch() {
        this.chartRef.request()
    }

    @Ref()
    chartRef: EChartImpl
}
