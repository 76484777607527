/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 报警等级
 * $t('enum.E_AlarmLevel.Normal')
 * $t('enum.E_AlarmLevel.Offline')
 * $t('enum.E_AlarmLevel.C')
 * $t('enum.E_AlarmLevel.D') 
 * $t('enum.E_AlarmLevel.Unknown') 
 * $t('enum.E_AlarmLevel.Offline2') 
 * $t('enum.E_AlarmLevel__long.Normal')
 * $t('enum.E_AlarmLevel__long.Offline')
 * $t('enum.E_AlarmLevel__long.C')
 * $t('enum.E_AlarmLevel__long.D') 
 * $t('enum.E_AlarmLevel__long.Unknown') 
 * $t('enum.E_AlarmLevel__long.Offline2') 
 */
export enum E_AlarmLevel {
    /**
     * 正常
     */
    Normal = 0,
    /**
     * 离线
     */
    Offline = 1,
    /**
     * C级
     */
    C = 2,
    /**
     * D级
     */
    D = 3,
    /**
     * 未知
     */
    Unknown = 4,
    /**
     * 离线
     */
    Offline2 = 5,
}

/**
 *  报警等级2
 * $t('enum.E_AlarmLevel2.A')
 * $t('enum.E_AlarmLevel2.B')
 * $t('enum.E_AlarmLevel2.C')
 * $t('enum.E_AlarmLevel2.D') 
 * $t('enum.E_AlarmLevel2.Unknown')
 * $t('enum.E_AlarmLevel2.Offline') 
 */
export enum E_AlarmLevel2 {    
    A = 0,
    B = 1,
    C = 2,
    D = 3,
    Unknown = 4,
    Offline = 5,
}

/**
 * 报警类型
 * $t('enum.E_AlarmType.Vibration')
 * $t('enum.E_AlarmType.Steepness')
 * $t('enum.E_AlarmType.Crest')
 * $t('enum.E_AlarmType.Temperature')
 * $t('enum.E_AlarmType.Voltage')
 * $t('enum.E_AlarmType.Offline')
 * $t('enum.E_AlarmType.Electricity')
 * $t('enum.E_AlarmType.Envelope')
 * $t('enum.E_AlarmType.HighFrequencyAcceleration')
 * 
 * 报警过滤
* $t('enum.E_AlarmType__Filter.Vibration')
 * $t('enum.E_AlarmType__Filter.Steepness')
 * $t('enum.E_AlarmType__Filter.Crest')
 * $t('enum.E_AlarmType__Filter.Temperature')
 * $t('enum.E_AlarmType__Filter.Voltage')
 * $t('enum.E_AlarmType__Filter.Offline')
 * $t('enum.E_AlarmType__Filter.Electricity')
 * $t('enum.E_AlarmType__Filter.Envelope')
 * $t('enum.E_AlarmType__Filter.HighFrequencyAcceleration')
 */
export enum E_AlarmType {
    /**
     * 振动烈度
     */
    Vibration = 1,
    /**
     * 峭度
     */
    Steepness = 2,
    /**
     * 波峰因数
     */
    Crest = 3,
    /**
     * 溫度
     */
    Temperature = 4,
    /**
     * 电压
     */
    Voltage = 5,
    /**
     * 离线
     */
    Offline = 6,
    /**
     * 电流
     */
    Electricity = 7,
    /**
     * 包络
     */
    Envelope = 8,
    /**
     * 高频加速度
     */
    HighFrequencyAcceleration = 9,
}

/**
 * 设备状态
 * $t('enum.E_DeviceStatus.Normal')
 * $t('enum.E_DeviceStatus.C')
 * $t('enum.E_DeviceStatus.D')
 * $t('enum.E_DeviceStatus.Offline')
 * $t('enum.E_DeviceStatus.FocusOn') 
 */
export enum E_DeviceStatus {
    /**
     * 在线
     */
    Normal = 0,
    /**
     * C级警报
     */
    C = 2,
    /**
     * D级警报
     */
    D = 3,
    /**
     * 离线
     */
    Offline = 1,
    /**
     * 重点关注
     */
    FocusOn = 4,
}

export const HZList = {
    HZ_44440: { label: '44440Hz', value: '44440' },
    HZ_6664: { label: '6664Hz', value: '6664' },
    HZ_6660: { label: '6660Hz', value: '6660' },
    HZ_3332: { label: '3332Hz', value: '3332' },
    HZ_3330: { label: '3330Hz', value: '3330' },    
    HZ_2000: { label: '2000Hz', value: '2000' },
    HZ_1666: { label: '1666Hz', value: '1666' },
    HZ_1660: { label: '1660Hz', value: '1660' },
    HZ_833: { label: '833Hz', value: '833' },
    HZ_416: { label: '416Hz', value: '416' },
    /**
     * 高频
     */
    HZ_51200: { label: '51.2kHz', value: '51200' },
    HZ_25600: { label: '25.6kHz', value: '25600' },
    HZ_12800: { label: '12.8kHz', value: '12800' },
    HZ_5120: { label: '5.12kHz', value: '5120' },
    HZ_2560: { label: '2.56kHz', value: '2560' },
}
