/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
/**
 * 用于label和value相同的情况 
 */
export function toOptionPair<T>(value: T) {
    return { label: value, value }
}

/** 
 */
export function toOption<T>(label: string, value: T) {
    return { label, value }
}

export function options2Map(options: readonly { label: string, value: string | number }[]) {
    return _.fromPairs(_.map(options, op => {
        return [op.value, op.label]
    }))
}
