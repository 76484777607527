/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmLevel, E_AlarmLevel2, E_AlarmType } from "../enums";
import { E_YesNo, IPageData, IPageParams } from "../types";
import { E_ModelPartial } from "./model.types";
import { E_Axis } from "./sensor.types";

/**
 * $t('enum.E_DealStatus.UnHandled')
 * $t('enum.E_DealStatus.AutoHandled')
 * $t('enum.E_DealStatus.ManualHandled')
 */
export enum E_DealStatus {
  UnHandled = 0,
  AutoHandled = 1,
  ManualHandled = 2,
}
/**
 * $t('enum.E_EventType.Threshold')
 * $t('enum.E_EventType.Offline')
 */
export enum E_EventType {
  /**
   * 阈值报警
   */
  Threshold = 1,
  /**
   * 离线报警
   */
  Offline = 2,
}

/**
 * 报警方式
 * $t('enum.E_AlarmMode.Eigenvalue')
 * $t('enum.E_AlarmMode.Wave')
 */
export enum E_AlarmMode {
  /**
   * 特征值
   */
  Eigenvalue = 1,
  /**
   * 波型
   */
  Wave = 2,
}

/**
 * 故障建议
 */
export interface IFaultSuggestItem {
  /**
   * 建议数组
   */
  suggestArray: Array<any>;
  /**
   * 类型
   * 0：取 suggestArray;1：取 suggest
   */
  type: number;
  /**
   * 建议字符串
   */
  suggest: string;
}

export type IGetFaultSuggestsData = IFaultSuggestItem[];

export interface IGetFaultanAlysisStatisticsNormal {
  /**
  * 部件类型
  */
  componentType: E_ModelPartial

  /**
   * 轴承故障
   */
  bearingBreath: number
  bearingPercentage: number

  /**
   * 不对中
   */
  misaligned: number;
  misalignedPercentage: number;
  /**
   * 设备基础松动
   */
  looseness: number;
  loosenessPercentage: number;
  /**
   * 不平衡
   */
  imbalance: number;
  imbalancePercentage: number;

  /**
   * 转定子气隙不均
   */
  unevenBreath: number;
  unevenBreathPercentage: number;

  /**
   * 共振
   */
  resonance: number;
  resonancePercentage: number;
}

/**
 * 风机
 */
export interface IGetFaultanAlysisStatisticsFan {
  componentType: E_ModelPartial.Fans
  /**
   * 风机叶片摩擦
   */
  fanBreath: number
  fanPercentage: number
}

/**
 * 水泵
 */
export interface IGetFaultanAlysisStatisticsPump {
  componentType: E_ModelPartial.WaterPump
  /**
   * 水泵叶片摩擦
   */
  pumpBreath: number
  pumpPercentage: number
}


/**
 * 齿轮箱
 */
export interface IGetFaultanAlysisStatisticsCogs {
  componentType: E_ModelPartial.Cogs
  /**
   * 齿轮箱不对中
   */
  gearBBreath: number
  gearBPercentage: number

  /**
   * 齿轮负载高
   */
  gearFBreath: number
  gearFPercentage: number

  /**
   * 齿轮磨损
   */
  gearMBreath: number
  gearMPercentage: number
}

export type IGetFaultanAlysisStatisticsData =
  IGetFaultanAlysisStatisticsNormal & (IGetFaultanAlysisStatisticsFan | IGetFaultanAlysisStatisticsPump | IGetFaultanAlysisStatisticsCogs)

export interface IQueryListPageParams extends IPageParams {
  companyId: number
  areaId?: number
  equipmentId?: number
  alarmType?: E_AlarmType[]
  dealStatus?: E_DealStatus[]
  level?: E_AlarmLevel[]
  startTime?: string
  endTime?: string
  mappingId?: number
}

export interface IAlarmListItem {
  /**
   * 报警轴
   */
  alarmAxis: string
  /**
   * 最新报警ID
   */
  alarmId: string
  /**
   * 报警等级
   */
  alarmLevel: E_AlarmLevel
  alarmType: E_AlarmType
  alarmMode: E_AlarmMode
  /**
   * 报警时间
   */
  createTime: string
  /**
   * 特征值id
   */
  dataId: string
  /**
   * 处理状态
   */
  dealStatus: E_DealStatus
  /**
   * 设备ID
   */
  equipmentId: number
  /**
   * 设备名称
   */
  equipmentName: string
  /**
   * 处理人
   */
  lastUser: string
  /**
   * 测点ID
   */
  mappingId: number
  /**
   * 测点名称
   */
  pointName: string
  /**
   * 处理备注
   */
  remark: string
  /**
   * 传感器编号
   */
  sensorId: string
  /**
   * 波形Id
   */
  waveId: number
}

export type IQueryListPageData = IPageData<IAlarmListItem>

export interface IAlarmDetail {
  /**
   * 报警轴
   */
  alarmAxis: string
  /**
   * 报警ID
   */
  id: string
  /**
   * 报警等级
   */
  alarmLevel: E_AlarmLevel
  alarmType: E_AlarmType
  alarmMode: E_AlarmMode
  /**
   * 连续报警次数
   */
  alarmTimes: number
  /**
   * 电压
   */
  battery: number

  /**
   * 报警类型
   */
  eventType: number
  /**
   * 报警时间
   */
  createTime: string
  /**
   * 特征值id
   */
  dataId: string
  /**
   * 处理状态
   */
  dealStatus: E_DealStatus

  /**
   * 操作人
   */
  lastUser: string
  /**
   * 测点ID
   */
  mappingId: number
  /**
   * 测点名称
   */
  mappingName: string
  /**
   * 处理备注
   */
  remark: string
  /**
   * 传感器编号
   */
  sensorId: string
  /**
   * 波形Id
   */
  waveId: number

  /**
   * 峭度
   */
  kurtX: number
  kurtY: number
  kurtZ: number

  /**
   * 波峰因数
   */
  peakX: number
  peakY: number
  peakZ: number

  /**
   * 速度
   */
  vx: number
  vy: number
  vz: number
  /**
   * 温度
   */
  tem: number
}

export interface IGetItemDetailParams extends IPageParams {
  startTime?: string
  endTime?: string
  mappingId: number
}

export type IGetItemDetailData = IPageData<IAlarmDetail>

export interface IDealAlarmRecordParam {
  /**
   * 报警ID
   */
  alarmId: number
  /**
   * 处理人
   */
  dealUser: string
  /**
   * 处理措施
   */
  remark: string
}


export interface IPopupListItem {
  /**
   * 报警轴
   */
  alarmAxis: E_Axis
  alarmLevel: E_AlarmLevel
  alarmMode: E_AlarmMode
  /**
   * 
   */
  alarmNo: string
  /**
   * 连续报警次数
   */
  alarmTimes: number
  /**
   * 报警类型
   */
  alarmType: E_AlarmType
  areaId: number
  areaName: string
  battery: number
  companyId: number
  createTime: string
  dataId: number
  /**
   * 
   */
  dealStatus: E_DealStatus
  equipmentId: number
  equipmentName: string
  eventType: E_EventType
  id: number
  /**
   * 峭度
   */
  kurtX: number
  kurtY: number
  kurtZ: number
  /**
   * 操作人
   */
  lastUser: string
  mappingId: number
  mappingName: string
  /**
   * 波峰因数
   */
  peakX: number
  peakY: number
  peakZ: number
  remark: string
  sensorId: string
  tem: number
  vx: number
  vy: number
  vz: number
  waveId: number
}

export type IPopupListParms = IPageParams
export type IPopupListData = IPageData<IPopupListItem>

export interface IAlarmRecordReadParams {
  ids: number[]
}
