/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 图表类型
 * $t('enum.E_ChartType.AcceleratedSpeed')
 * $t('enum.E_ChartType.Vibration')
 * $t('enum.E_ChartType.Displacement')
 * $t('enum.E_ChartType.Temperature')
 * $t('enum.E_ChartType.TemperatureRise')
 * $t('enum.E_ChartType.Peakedness')
 * $t('enum.E_ChartType.CrestFactor')
 * $t('enum.E_ChartType.EnvelopeDemodulationValue')
 * $t('enum.E_ChartType.Speed')
 * $t('enum.E_ChartType.AccelerationTimeDomain')
 * $t('enum.E_ChartType.AccelerationFrequencyDomain')
 * $t('enum.E_ChartType.VelocityDomain')
 * $t('enum.E_ChartType.VelocityFrequencyDomain')
 * $t('enum.E_ChartType.EnvelopeSpectrum')
 * $t('enum.E_ChartType.EnvelopeSignal')
 * $t('enum.E_ChartType.DisplacementTimeDomain')
 * $t('enum.E_ChartType.DisplacementSpectrum')
 */
export enum E_ChartType {
  AcceleratedSpeed = 1, // 加速度
  Vibration = 2, // 振动烈度
  Displacement = 3, // 位移
  Temperature = 4, // 温度
  TemperatureRise = 5, // 温升
  Peakedness = 6, // 峭度
  CrestFactor = 7, // 波峰因数
  EnvelopeDemodulationValue = 8, // 包络解调值
  Speed = 9, // 转速
  AccelerationTimeDomain = 100, // 加速度时域
  AccelerationFrequencyDomain = 101, // 加速度频域
  VelocityDomain = 102, // 速度时域
  VelocityFrequencyDomain = 103, // 速度频域
  EnvelopeSpectrum = 104, // 包络频谱
  EnvelopeSignal = 105, // 包络信号
  DisplacementTimeDomain = 106, // 位移时域
  DisplacementSpectrum = 107, // 位移频谱
}
