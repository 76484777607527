/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * $t('enum.E_UpkeepRepairHandleStatus.PendingAssign')
 * $t('enum.E_UpkeepRepairHandleStatus.PendingCheck')
 * $t('enum.E_UpkeepRepairHandleStatus.Resolve')
 */
export enum E_UpkeepRepairHandleStatus {
    /**
     * 待分配
     */
    PendingAssign = 1,
    /**
     * 待检测
     */
    PendingCheck = 2,
    /**
     * 已结单
     */
    Resolve = 3,
}