/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Provide } from 'vue-property-decorator'
import { CollapseItemImpl } from './CollapseItem/CollapseItemImpl'
import { E_CollapseItemStatus } from './types'
import { EChartImpl } from '../EChart/EChartImpl'

@Component({
    name: 'Collapse'
})
export class CollapseImpl extends Vue {

    @Provide()
    collapse = this

    collapseItems: CollapseItemImpl[] = []

    @Provide()
    registerCollapseItem(item: CollapseItemImpl) {
        this.collapseItems.push(item)
        return () => {
            this.collapseItems = _.filter(this.collapseItems, collapseItem => collapseItem !== item)
        }
    }

    charts: EChartImpl[] = []

    @Provide()
    registerChildECharts(chart: EChartImpl) {
        this.charts.push(chart)
        return () => {
            this.charts = _.filter(this.charts, c => c !== chart)
        }
    }

    resizeCharts() {
        _.each(this.charts, c => {
            c.resizeChart()
        })
    }

    maxCollapseItem(item: CollapseItemImpl) {
        _.each(this.collapseItems, (child: CollapseItemImpl) => {
            child.status = E_CollapseItemStatus.Minimum
        })
        item.status = E_CollapseItemStatus.Full
        this.$nextTick(() => {
            this.$emit('changed')
            this.resizeCharts()
        })
    }

    clickCollapseItem(item: CollapseItemImpl) {
        let targetStatus = item.status
        let childStatus = E_CollapseItemStatus.Minimum
        if (targetStatus === E_CollapseItemStatus.Full || targetStatus === E_CollapseItemStatus.Minimum) {
            childStatus = E_CollapseItemStatus.Normal
        }
        _.each(this.collapseItems, (child: CollapseItemImpl) => {
            child.status = childStatus
        })
        if (targetStatus === E_CollapseItemStatus.Normal) {
            item.status = E_CollapseItemStatus.Full
        }
        this.$nextTick(() => {
            this.$emit('changed')
            this.resizeCharts()
        })
    }
}
