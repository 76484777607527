/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * $t('enum.E_ErrorCodes.AccountExpired')
 * $t('enum.E_ErrorCodes.SensorNotExisted')
 */
export enum E_ErrorCodes {
    /**
     * 账号到期
     */
    AccountExpired = 1037,

    /**
     * 传感器不存在
     */
    SensorNotExisted = 1024,
}
