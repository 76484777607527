/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "@/api/types"
import { E_UpkeepRepairHandleStatus } from "../types"

/**
 * 处理结果
 * $t('enum.E_UpkeepHandleResult.UnHandle')
 * $t('enum.E_UpkeepHandleResult.Finished')
 */
export enum E_UpkeepHandleResult {
    /**
     * 未处理
     */
    UnHandle = 1,
    /**
     * 处理完成
     */
    Finished = 2,
}

/**
 * 保养等级
 * $t('enum.E_UpkeepLevel.First')
 * $t('enum.E_UpkeepLevel.Second')
 * $t('enum.E_UpkeepLevel.Third')
 */
export enum E_UpkeepLevel {
    /**
     * 一级
     */
    First = 1,
    /**
     * 二级
     */
    Second = 2,
    /**
     * 三级
     */
    Third = 3,
}

export interface IUpkeepContentItem {
    /**
     * 保养内容
     */
    upkeepContent: string
    /**
     * 保养库编号
     */
    upkeepLibSn?: string
    /**
     * 保养名称
     */
    upkeepName?: string
}

export interface IUpkeepItem {
    areaId: number
    companyId: number
    /**
     * 保养内容
     */
    content: IUpkeepContentItem[]
    equipmentId: number
    remark: string
    /**
     * 保养等级
     */
    upkeepLevel: E_UpkeepLevel
    /**
     * 保养名
     */
    upkeepName: string
    /**
     * 保养人
     */
    userId: number
}

export type IUpkeepAddParams = IUpkeepItem

export type IUpkeepAddContentParams = IUpkeepContentItem


export interface IUpkeepDetailParams {
    id: number
}

export interface IUpkeepDetailData {
    areaId: number
    areaName: string
    companyId: number
    companyName: string
    content: {
        id: number
        upkeepContent: string
    }[]
    /**
     * 
     */
    createName: string
    createdTime: string
    examineReason: string
    file1: string
    file2: string
    file3: string
    handle: {
        assignTime: string
        file1: string
        file2: string
        file3: string
        img1FieldId: string
        img2FieldId: string
        img3FieldId: string
        id: number
        status: 0
        upkeepId: number
        handleDesc: string
        handleName: string
        handleTime: string
    }[]
    id: number
    img1FieldId: string
    img2FieldId: string
    img3FieldId: string
    remark: string
    status: E_UpkeepRepairHandleStatus
    upkeepLevel: E_UpkeepLevel
    upkeepName: string
    upkeepSn: string
    upkeepUser: number
    upkeepUserName: string
    equipmentId: number
    equipmentName: string
}

export type IUpkeepPageListParams = IPageParams & Partial<{
    companyId: number
    areaId: number
    /**
     * 维修员
     */
    userId: number
}>

export interface IUpkeepPageListItem {
    companyName: string
    areaName: string
    createName: string
    createdTime: string
    id: number
    status: E_UpkeepRepairHandleStatus
    successTime: string
    upkeepName: string
    upkeepSn: string
    upkeepUserName: string
}

export type IUpkeepPageListData = IPageData<IUpkeepPageListItem>

export interface IUpkeepExamineParams {
    companyId: number
    areaId: number
    id: number
    remark: string
    upkeepLevel: E_UpkeepLevel
    upkeepName: string
    userId: number
    content: IUpkeepContentItem[]
}
