/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import VueRouter from "vue-router";
import Cookies from "js-cookie";
import { ILoginData } from "@/api/moudules/login.types";

export function markAuth(data: ILoginData) {
    Cookies.set('token', data.token);//放置token到cookie
    localStorage.setItem('user', data.userName) // 保存用户到本地会话
}

export function cleanAuth() {
    Cookies.remove('token');//放置token到cookie
    localStorage.removeItem('user') // 保存用户到本地会话
}

export function goToLogin(router: VueRouter) {
    cleanAuth()
    router.replace({
        name: 'login'
    })
}
