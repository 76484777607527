/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IOptionNumberItem } from "../types";

/**
 * 平面图上传状态
 * $t('enum.E_AreaPicStatus.NoUpload')
 * $t('enum.E_AreaPicStatus.Uploaded')
 */
export enum E_AreaPicStatus {
    /**
     * 已上传
     */
    Uploaded = 1,
    /**
     * 未上传
     */
    NoUpload = 2,
}

/**
 * 公司/子公司状态
 * $t('enum.E_CompanyStatus.Normal')
 * $t('enum.E_CompanyStatus.Block')
 */
export enum E_CompanyStatus {
    /**
     * 正常
     */
    Normal = 0,
    /**
     * 停用
     */
    Block = 1,
}

/**
 * 公司
 *
 */
export type ICompanyItem = IOptionNumberItem


export type IGetCompanyListData = ICompanyItem[];

/**
 *
 */
export interface IFindCompanyItem {
    address: string;
    children: null | any[];
    city: string;
    code: null | any;
    createBy: string;
    createTime: string;
    delFlag: number;
    id: number;
    level: number;
    name: string;
    parentId: number;
    parentName: string;
    province: string;
    updateBy: string;
    updateTime: string;
    uuid: number;
}
export type IFindCompanyTreeData = IFindCompanyItem[];

export interface IFindCompanyTreeParams {
    cascaderType: number;
}

export interface IFindCompanyPageParams {
    companyId?: number
    areaId?: number
}

export interface IAreaListItem {
    areaId: number
    areaName: string
    createTime: string
    equipmentCount: number
    picStatus: E_AreaPicStatus
    /**
     * 横坐标
     */
    abscissa?: string
    /**
     * 纵坐标
     */
    ordinate?: string
    fileUrl?: string
}

export interface IFindCompanyPageItem {
    id: number
    name: string
    createTime: string
    company: {
        companyId: number
        name: string
        createTime: string
        equipmentCount: number
        status: E_CompanyStatus
        areaList: IAreaListItem[]
        fileUrl?: string
    }[]
}

export type IFindCompanyPageData = IFindCompanyPageItem[]

export interface ICreateSubCompanyParams {
    /**
     * 有值表示更新，无值表示新增
     */
    id?: number
    companyName: string
    province: string
    city: string
    address: string
    status: E_CompanyStatus
}

export interface ISubCompanyItem {
    /**
       * 详细地址
       */
    address: string;
    /**
     * 所在市
     */
    city: string;
    code: string;
    createBy: string;
    createTime: string;
    delFlag: number;
    id: number;
    name: string;
    parentId: number;
    /**
     * 所在省
     */
    province: string;
    /**
     * 状态0正常1停用
     */
    status: E_CompanyStatus
    updateBy: string;
    updateTime: null;

    fileId: null
    fileUrl: null
}

export enum E_CompanyOrArea {
    Company = 0,
    Area = 1,
}

export interface IDelCompanyOrAreaParams {
    companyId?: number
    areaId?: number
    flag: E_CompanyOrArea
}
