/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmType } from "../enums"
import { E_YesNoNumber, IPageData, IPageParams } from "../types"
import { E_ChannelType, E_CollectCardType } from "./collectCard.types"
import { IFaultSetting } from "./fault.types"
import { IFileListItem } from "./file.types"
import { E_Axis, E_CollectMode, E_CollectWork, E_InstallMethod, E_MHFFlag, E_SensorDirection, E_SensorKlass, E_SensorOpenWave, E_SensorType, E_StartStatus } from "./sensor.types"
import { E_UseLearn } from "./sensorLearn.types"

/**
 * $t('enum.E_DriveType.DrivePort')
 * $t('enum.E_DriveType.NonDrivePort')
 */
export enum E_DriveType {
    /**
     * 驱动端
     */
    DrivePort = 1,
    /**
     * 非驱动端
     */
    NonDrivePort = 2,
}

/**
 * 通信选择
 * $t('enum.E_CommChoice.Gateway')
 * $t('enum.E_CommChoice.CollectCard')
 */
export enum E_CommChoice {
    /**
     * 网关
     */
    Gateway = 1,
    /**
     * 采集卡
     */
    CollectCard = 2,
}

/*
 * 载波反转
 * $t('enum.E_CarrierReversal.NotReversal')
 * $t('enum.E_CarrierReversal.Reversal')
 */
export enum E_CarrierReversal {
    /**
     * 载波不反转
     */
    NotReversal = 0,
    /**
     * 载波反转
     */
    Reversal = 1,
}

export interface IPointMapping {
    areaId: number
    companyId: number
    driveType: E_DriveType
    equipmentId: number
    extendId: number
    gearId: number
    gearName?: string
    location: string
    pointName: string
    sort: number
}

export interface ISensorSetListItem {
    /**
     * 轴向:X, Y, Z, XYZ',
     */
    axis: E_Axis
    /**
     * 中频，高频标志位
     */
    mhfFlag?: E_MHFFlag
    /**
     * 采样频率
     */
    mhfHz?: string
    /**
     * 
     */
    mhfUse: E_YesNoNumber

    /**
     * 传感器灵敏度
     */
    sensorSensitivity: number
    /**
     * 偏置电压
     */
    voltage: number
    /**
     * 采集间隔
     */
    samplingInterval: number
    /**
     * 传感器编号
     */
    sensorId: number
    /**
     * 点数
     */
    wavePoint: number
}

export interface ISensorInfo extends ISensorInfoBase {
    /**
     * 阈值名称
     */
    alarmThresholdName: string
    /**
     * 网关名称
     */
    gateWayName: number
    /**
     * 采样频率
     */
    mhfHz: string
    /**
     * 波形点数
     */
    wavePoint: number
    /**
     * 传感器id
     */
    id: string
    /**
     * VB50sim卡号
     */
    mqttSim?: string
}

export interface ISensorUpload extends ISensorInfoBase {
    /**
     * 通信选择
     */
    commChoice: E_CommChoice

    cardChannelId: number

    /**
     * 采集卡id
     */
    cardId?: number

    /**
     * 传感器id
     */
    sensorId: string
    /**
     * 采样倍数
     */
    samplingMultiple: number
}

export interface ISensorInfoBase {
    /**
     * 报警时是否采集
     */
    alarmCollect: E_YesNoNumber
    /**
     * 报警间隔
     * 单位(分钟)
     */
    alarmInterval: number
    /**
     * 区域id
     */
    areaId: number
    /**
     * 采集模型
     */
    collectMode: E_CollectMode
    /**
     * 采集间隔
     */
    collectSpace: number
    /**
     * 每天采集时间
     */
    collectTime: number
    /**
     * 工作方式
     */
    collectWork: E_CollectWork
    /**
     * 公司id
     */
    companyId: number
    /**
     * VB24H特征值上传数
     */
    dataCount: number
    /**
     * VB24H特征值采样时间
     */
    dataSampling: number
    /**
     * VB24H特征值上传时间
     */
    dataUpload: number
    /**
     * 设备id
     */
    equipmentId: number
    /**
     * 网关ID
     */
    gatewayId?: number
    /**
     * VB24H硬件版本号
     */
    hardwareVersion: number

    /**
     * 安装方式
     */
    installMode: E_InstallMethod
    /**
     * 启用故障自诊断
     */
    isFault: E_YesNoNumber
    /**
     * VB24H侦听时长
     */
    listening: number
    /**
     * mac地址
     */
    mac: string
    /**
     * 测点ID
     */
    mappingId: number
    /**
     * 传感器名称
     */
    name: number
    /**
     * VB24H启用波形采集，1开启，2关闭
     */
    openWave: E_SensorOpenWave
    /**
     * 传感器配置详情
     */
    sensorSetList: ISensorSetListItem[]
    /**
     * VB24H传感器类型
     */
    sensorType: E_SensorType
    /**
     * VB24H信号强度
     */
    signalHeart: number
    /**
     * 休眠时间
     */
    sleepT: number
    /**
     * VB24H软件版本号
     */
    softwareVersion: string
    /**
     * 传感器类型
     */
    type: E_SensorKlass
    /**
     * VB24H上传率
     */
    uploadRate: string
    /**
     * VB24H无限通道
     */
    wirelessChannel: number
    /**
     * VB24H无线频段
     */
    wirelessFrequency: string

    /**
     * 传感器灵敏度
     */
    sensorSensitivity: number
    /**
     * 偏置电压
     */
    voltage: number

    /**
     * 1:水平，2：径向，3：垂直
     */
    x1: E_SensorDirection
    y1: E_SensorDirection
    z1: E_SensorDirection

    /**
     * VB50密码
     */
    mqttPassword?: string
    /**
     * VB50IP地址
     */
    mqttServerIp?: string
    /**
     * VB50端口号
     */
    mqttServerPort?: string
    /**
     * VB50用户名
     */
    mqttUserName?: string
}

export interface IAlarmTypeVOData {
    /**
     * 有效报警间隔次数，超过几次才报警
     */
    effectiveIntervalCount: number
    // /**
    //  * 有效报警间隔时间，分钟
    //  */
    // effectiveIntervalTime: number
    /**
     * 报警间隔次数，超过几次不报警
     */
    intervalCount: number
    /**
     * 报警间隔时间，分钟
     */
    intervalTime: number
}

export interface IAlarmTypeVO extends IAlarmTypeVOData {
    /**
     * 报警类型
     */
    alarmType: E_AlarmType
}


export type ISensorAddPointMappingParams = IPointMapping


export type ISensorAddSensorParams = ISensorUpload

export interface ISensorAddSensorAlarmParams {
    /**
     * 报警阈值映射id
     */
    alarmThresholdId: number
    /**
     * 启用故障自诊断
     */
    isFault: E_YesNoNumber
    /**
     * 传感器id
     */
    sensorId: string
    /**
     * 报警类型配置选择
     */
    alarmTypeVOS: IAlarmTypeVO[]

    /**
     * VB24H中频Z轴启停
     */
    lowZSs: number
    /**
     * VB24H高频Z轴启停
     */
    highZSs: number

}

export interface ISensorAddSensorLearnParams {
    isUse: E_UseLearn
    /**
     * ai自学习结束时间
     */
    learnEndTime: string
    /**
     * ai自学习开始时间
     */
    learnStartTime: string
    /**
     * 启动ai自学习
     */
    openLearn: E_StartStatus
    /**
     * 测点ID
     */
    mappingId: number
    /**
     * 传感器id
     */
    sensorId: string
    /**
     * 传感器学习id
     */
    sensorLearnId: number
}



export interface ISensorCheckPasswordParams {
    checkPassword: string
}

export interface ISensorCopyFaultSettingBySensorIdParams {
    /**
     * 传感器编号-被复制传感器
     */
    oldSensorId: string
    /**
     * 复制到传感器编号
     */
    sensorIds: string[]
}

export interface ISensorDelMappingParams {
    id: number
}
export type ISensorEditFaultParams = IFaultSetting

export interface ISensorGetPointMappingDetailParams {
    id: number
}

export interface ISensorGetPointMappingDetailData extends IPointMapping {
    /**
     * 采样倍数
     */
    samplingMultiple: number
    /**
     * 采集卡通道id
     */
    cardChannelId?: number

    /**
     * 通信选择
     */
    commChoice: E_CommChoice

    /**
     * 采集卡id
     */
    cardId?: number
    /**
     * 部件关联id
     */
    componentId: number

    /**
     * 传感器id
     */
    sensorId?: string

    /**
     * 图片地址
     */
    fileList: IFileListItem[]
    /**
     * 第一张图
     */
    defaultFile: IFileListItem
    id: number

}

export interface ISensorGetSensorAlarmDetailParams {
    sensorId: string
}

export interface ISensorGetSensorAlarmDetailData extends ISensorAddSensorAlarmParams {
    /**
     * 传感器id
     */
    id: number
    /**
     * 启动ai自学习
     */
    openLearn: E_StartStatus
    /**
     * 阈值名称
     */
    alarmThresholdName: string
}

export interface ISensorGetSensorDetailParams {
    sensorId: string
}

export type ISensorGetSensorDetailData = ISensorInfo

export interface ISensorGetSensorLearnDetailParams {
    sensorId: string
}
export interface ISensorGetSensorLearnDetailData {
    /**
     * 传感器id
     */
    id: string
    /**
     * ai自学习结束时间
     */
    learnEndTime: string
    /**
     * ai自学习开始时间
     */
    learnStartTime: string
    /**
     * 启动ai自学习
     */
    openLearn: E_StartStatus
}

export type ISensorPointPageListParams = IPageParams & Partial<{
    companyId: number
    areaId: number
    equipmentId: number
    sensorId: string
}>

export interface ISensorPointPageListItem {
    commChoice: E_CommChoice

    cardChannelId: number
    channelName: string

    cardId: number
    cardName: string
    channelType: E_ChannelType

    gatewayId: number
    gatewayName: string


    /**
     * 区域id
     */
    areaId: string
    /**
     * 是否绑定报警灯
     */
    bindLamp: number
    /**
     * 
     */
    collectMode: E_CollectMode
    /**
     * 公司/区域名称
     */
    companyAreaName: string
    /**
     * 
     */
    companyId: string
    /**
     * 
     */
    equipmentId: string
    /**
     * 
     */
    equipmentName: string
    /**
     * 测点id
     */
    id: number
    /**
     * 测点名称
     */
    pointName: string
    /**
     * 
     */
    sensorId: string
    /**
     * 传感器名称
     */
    sensorName: string
    /**
     * 传感器编号
     */
    sensorType: string
    /**
     * 阈值名称
     */
    thresholdName: string
}

export type ISensorPointPageListData = IPageData<ISensorPointPageListItem>

export interface ISensorQueryFaultSettingBySensorIdParams {
    sensorId: string
}

export interface ISensorQueryFaultSettingBySensorIdData extends IFaultSetting {

}

export interface ISensorUnbindSensorParams {
    id: number
}


export interface ISensorUpdatePointMappingParams extends IPointMapping {
    /**
     * 测点id
     */
    id: number
    /**
     * 修改前部件关联id
     */
    oldComponentId: number
}


export interface ISensorUpdateSensorParams {

}

export interface ISensorUploadFileParams {
    fileIdArray: number[]
    id: number
}

export interface ISensorMqttReadInfoParams {
    sensorId: string
}

export interface ISensorMqttReadSimParams {
    sensorId: string
}

export interface ISensorLoRoInfoBase {
    /**
     * 传感器密钥
     */
    aesKey: string
    /**
     * 接收频率
     */
    receiveFreq: number
    /**
     * 接收扩频因子
     */
    receiveSpsp: number
    /**
     * 发射频率
     */
    sendFreq: number
    /**
     * 发射扩频因子
     */
    sendSpsp: number
    /**
     * 传感器id
     */
    sensorId: string
}

export type ISensorSettingLoRaInfoParams = ISensorLoRoInfoBase

export interface ISensorLoRoInfo extends ISensorLoRoInfoBase {

    id: number
    /**
     * 接收载波反转
     */
    receiveCarrierReversal: E_CarrierReversal

    /**
     * 发送载波反转
     */
    sendCarrierReversal: E_CarrierReversal
}


export interface ISensorGetLoRaInfoParams {
    sensorId: string
}

export type ISensorGetLoRaInfoData = ISensorLoRoInfo

export interface ISensorGetParamsParams {
    sensorId: string
}

export interface ISensorGetParamsData {
    /**
     * 硬件版本
     */
    hardwareVersion: string
    /**
     * 传感器id
     */
    sensorId: string
    /**
     * 软件版本
     */
    softwareVersion: string

    /**
     * 信号强度
     */
    signalHeart: string
}

export interface ISensorReadSensorVersionParams {
    sensorId: string
}

export interface ISensorReadSensorLoraParams {
    sensorId: string
}

export interface ISensorReadSensorSignalParams {
    sensorId: string
}

export interface ISensorSettingDateTimeParams {
    sensorId: string
}
