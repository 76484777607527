/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * $t('enum.E_Eigenvalue2.EffectiveValueOfAcceleration')
 * $t('enum.E_Eigenvalue2.MarginFactor')
 * $t('enum.E_Eigenvalue2.Variance')
 * $t('enum.E_Eigenvalue2.StandardDeviation')
 * $t('enum.E_Eigenvalue2.PulseFactor')
 * $t('enum.E_Eigenvalue2.VelocityEffectiveValue')
 * $t('enum.E_Eigenvalue2.PeakDisplacement')
 * $t('enum.E_Eigenvalue2.EnvelopeValue')
 * $t('enum.E_Eigenvalue2.PeakAcceleration')
 * $t('enum.E_Eigenvalue2.PeakValueOfAcceleration')
 * $t('enum.E_Eigenvalue2.Kurtosis')
 * $t('enum.E_Eigenvalue2.KurtosisIndex')
 * $t('enum.E_Eigenvalue2.Skewness')
 * $t('enum.E_Eigenvalue2.SkewIndex')
 * $t('enum.E_Eigenvalue2.CrestFactor')
 */
export enum E_Eigenvalue2 {
    /**
     * 加速度有效值
     */
    EffectiveValueOfAcceleration = 'EffectiveValueOfAcceleration',
    /**
     * 裕度因子
     */
    MarginFactor = 'MarginFactor',
    /**
     * 方差
     */
    Variance = 'Variance',
    /**
     * 标准差
     */
    StandardDeviation = 'StandardDeviation',
    /**
        * 脉冲因子
        */
    PulseFactor = 'PulseFactor',
    /**
     * 速度有效值
     */
    VelocityEffectiveValue = 'VelocityEffectiveValue',
    /**
    * 位移峰值
    */
    PeakDisplacement = 'PeakDisplacement',
    /**
     * 包络值
     */
    EnvelopeValue = 'EnvelopeValue',
    /**
    * 加速度峰值
    */
    PeakAcceleration = 'PeakAcceleration',
    /**
     * 加速度峰峰值
     */
    PeakValueOfAcceleration = 'PeakValueOfAcceleration',
    /**
        * 峭度
        */
    Kurtosis = 'Kurtosis',
    /**
     * 峭度指标
     */
    KurtosisIndex = 'KurtosisIndex',
    /**
    * 歪度
    */
    Skewness = 'Skewness',

    /**
   * 歪度指标
   */
    SkewIndex = 'SkewIndex',
    /**
     * 波峰因子
     */
    CrestFactor = 'CrestFactor',
}

export interface IGetAllParams {
    mappingId: string
    waveId: string
}

export interface IEigenValueItem {
    /**
     * 加速度有效值
     */
    z1: number
    /**
     * 加速度峰值az
     */
    z2: number
    /**
     * 
     */
    // z3: number
    /**
     * 加速度峰峰值
     */
    z4: number
    /**
     * 峭度 qZ
     */
    z5: number
    /**
     * 峭度指标
     */
    z6: number
    /**
     * 歪度 yZ
     */
    z7: number
    /**
     * 歪度指标
     */
    z8: number
    /**
     * 波峰因子 bfZ
     */
    z9: number
    /**
     * 裕度因子 uZ
     */
    z10: number
    /**
     * 方差
     */
    z11: number
    /**
     * 标准差
     */
    z12: number
    /**
     * 脉冲因子
     */
    z13: number
    /**
     * 速度有效值 vZ pyZ
     */
    z14: number
    /**
     * 位移峰值 sZ
     */
    z15: number
    /**
     * 包络值 envelopeZ
     */
    z16: number
}

export interface IGetAllData {
    xs: IEigenValueItem
    ys: IEigenValueItem
    zs: IEigenValueItem
}
