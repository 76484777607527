/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { ISerorData } from "@/api/moudules/data.types";
import { E_SensorDirection } from "@/api/moudules/sensor.types";
import _ from "lodash";

/**
 * $t('enum.E_DataSearchTableCol.VibrationIntensity')
 * $t('enum.E_DataSearchTableCol.Acceleration')
 * $t('enum.E_DataSearchTableCol.Displacement')
 * $t('enum.E_DataSearchTableCol.WavePeakFactor')
 * $t('enum.E_DataSearchTableCol.Kurtosis')
 * $t('enum.E_DataSearchTableCol.Temperature')
 * $t('enum.E_DataSearchTableCol.RotationalSpeed')
 * $t('enum.E_DataSearchTableCol.ElectricCurrent')
 * $t('enum.E_DataSearchTableCol.Voltage')
 * $t('enum.E_DataSearchTableCol.DipAngle')
 * $t('enum.E_DataSearchTableCol.Envelope')
 * $t('enum.E_DataSearchTableCol.AZHigh')
 */
export enum E_DataSearchTableCol {
    VibrationIntensity = 0,
    Acceleration = 1,
    Displacement = 2,
    WavePeakFactor = 3,
    Kurtosis = 4,
    Temperature = 5,
    RotationalSpeed = 6,
    ElectricCurrent = 7,
    Voltage = 8,
    DipAngle = 9,
    Envelope = 10,
    AZHigh = 11,
}

export const DataSearchTableColsList = Object.freeze([
    E_DataSearchTableCol.VibrationIntensity,
    E_DataSearchTableCol.Acceleration,
    E_DataSearchTableCol.Displacement,
    E_DataSearchTableCol.WavePeakFactor,
    E_DataSearchTableCol.Kurtosis,
    E_DataSearchTableCol.Temperature,
    E_DataSearchTableCol.RotationalSpeed,
    // E_DataSearchTableCol.ElectricCurrent,
    E_DataSearchTableCol.Voltage,
    // E_DataSearchTableCol.DipAngle,
    E_DataSearchTableCol.Envelope,
    E_DataSearchTableCol.AZHigh,
])

export interface IMappingConfig {
    x1: E_SensorDirection
    y1: E_SensorDirection
    z1: E_SensorDirection
}

export function mapingXYZToDirection<T>(mapping: IMappingConfig, from: [T, T, T]) {
    const [x, y, z] = from
    const {
        x1, y1, z1
    } = mapping
    if (x1 === E_SensorDirection.Horizontal) {
        if (y1 === E_SensorDirection.Axial) {
            if (z1 === E_SensorDirection.Vertical) {
                return [x, z, y]
            }
        } else if (y1 === E_SensorDirection.Vertical) {
            if (z1 == E_SensorDirection.Axial) {
                return [x, y, z]
            }
        }
    } else if (x1 === E_SensorDirection.Axial) {
        if (y1 === E_SensorDirection.Horizontal) {
            if (z1 === E_SensorDirection.Vertical) {
                return [y, z, x]
            }
        } else if (y1 === E_SensorDirection.Vertical) {
            if (z1 === E_SensorDirection.Horizontal) {
                return [z, y, x]
            }
        }
    } else if (x1 === E_SensorDirection.Vertical) {
        if (y1 === E_SensorDirection.Horizontal) {
            if (z1 === E_SensorDirection.Axial) {
                return [y, x, z]
            }
        } else if (y1 === E_SensorDirection.Axial) {
            if (z1 === E_SensorDirection.Horizontal) {
                return [z, x, y]
            }
        }
    }
    // console.error('invalid mapping:', mapping)
    return from
}

export function mappingSensorXYZToDirection<T extends ISerorData>(mapping: IMappingConfig, data: T) {
    const [a_H, a_V, a_A] = mapingXYZToDirection(mapping, [data.ax, data.ay, data.az])
    const [s_H, s_V, s_A] = mapingXYZToDirection(mapping, [data.sx, data.sy, data.sz])
    const [v_H, v_V, v_A] = mapingXYZToDirection(mapping, [data.vx, data.vy, data.vz])
    const [q_H, q_V, q_A] = mapingXYZToDirection(mapping, [data.qx, data.qy, data.qz])
    const [bf_H, bf_V, bf_A] = mapingXYZToDirection(mapping, [data.bfX, data.bfY, data.bfZ])
    const [r_H, r_V, r_A] = mapingXYZToDirection(mapping, [data.rx, data.ry, data.rz])
    const [py_H, py_V, py_A] = mapingXYZToDirection(mapping, [data.pyX, data.pyY, data.pyZ])
    const [envelope_H, envelope_V, envelope_A] = mapingXYZToDirection(mapping, [data.envelopeX, data.envelopeY, data.envelopeZ])
    return {
        ...data,
        a_H, a_V, a_A,
        s_H, s_V, s_A,
        v_H, v_V, v_A,
        q_H, q_V, q_A,
        bf_H, bf_V, bf_A,
        r_H, r_V, r_A,
        py_H, py_V, py_A,
        envelope_H, envelope_V, envelope_A,
    }
}

export function colShow(cols: readonly E_DataSearchTableCol[]) {
    const checkSet = new Set(cols)
    const has = x => checkSet.has(x)
    return {
        VibrationIntensity: has(E_DataSearchTableCol.VibrationIntensity),
        Acceleration: has(E_DataSearchTableCol.Acceleration),
        Displacement: has(E_DataSearchTableCol.Displacement),
        WavePeakFactor: has(E_DataSearchTableCol.WavePeakFactor),
        Kurtosis: has(E_DataSearchTableCol.Kurtosis),
        Temperature: has(E_DataSearchTableCol.Temperature),
        RotationalSpeed: has(E_DataSearchTableCol.RotationalSpeed),
        ElectricCurrent: has(E_DataSearchTableCol.ElectricCurrent),
        Voltage: has(E_DataSearchTableCol.Voltage),
        DipAngle: has(E_DataSearchTableCol.DipAngle),
        Envelope: has(E_DataSearchTableCol.Envelope),
        AZHigh: has(E_DataSearchTableCol.AZHigh),
    }
}

export function hasWaveData(data: ISerorData) {
    const {
        qx, qy, qz,
        bfX, bfY, bfZ,
        
    } = data
    return _.some([qx, qy, qz, bfX, bfY, bfZ,], x => x && !_.isNil(x.value));
}
