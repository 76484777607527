<template>
    <div class="radio-options">
        <div class="name"> {{ name }}</div>
        <div v-for="(item, i) in options" class="item" @click="onClick(item.value)">
            <input class="radio" :class="{ 'radio-on': item.value == active }" type="radio" name="type" v-model="active"
                :value="item.value" />
            <span class="radio-options__text" :class="{ 'text-on': item.value == active }"> {{ item.label }}</span>
        </div>
        <slot></slot>
    </div>
</template>
<script>
// 波形记录切换图表按钮
export default {
    name: "RadioOptions",
    data() {
        return {
            active: "",// 当前选中
        }
    },
    props: {
        name: String, // 名称
        chartActive: [String, Number], // 选中
        options: Array, // 选项
    },
    watch: {
        chartActive(new_val, old_val) {
            this.active = new_val;
        }
    },
    created() {
        this.active = this.chartActive;
    },
    methods: {
        onClick(value) {
            this.active = value;
            this.$emit("handleChange", this.active);
        },
    }
}
</script>
<style lang="scss" scoped>
.radio-options {    
    // width: 100%;
    height: 32px;
    display: flex;
    justify-content: flex-start;
    align-content: center;

    .name {        
        margin-left: 24px;
        margin-right: 4px;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        line-height: 32px;
        text-align: center;
    }

    .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-content: center;
        cursor: pointer;
        margin-left: 8px;

        .radio {
            width: 16px;
            height: 16px;
            cursor: pointer;
            margin: auto;
        }

        .radio-on {
            appearance: none;
            -moz-appearance: none;
            /* Firefox */
            -webkit-appearance: none;
            width: 16px;
            height: 16px;
            background-image: url("@/assets/image/headbar/checked.png");
            background-repeat: no-repeat;
            background-size: 110%;
            margin: auto;
        }

        .radio-options__text {
            text-align: center;
            line-height: 32px;
            color: rgba(206, 206, 206, 1);
            font-size: 14px;
            margin-left: 2px;
        }

        .text-on {
            color: rgba(166, 243, 10, 1);
        }
    }
}
</style>