/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * 范围
 * $t('enum.E_AuthorityRange.CompanyAll')
 * $t('enum.E_AuthorityRange.Custom')
 */
export enum E_AuthorityRange {
    /**
     * 公司所有权限
     */
    CompanyAll = 0,
    /**
     * 自定义权限
     */
    Custom = 1,
}

export enum E_CheckStatus {
    UnChecked = 0,
    Checked = 1,
}


export interface IAuthorityRecordParamsAdd {
    areaIds: number[]
    /**
     * 字符
     */
    authorityCharacter: string
    authorityName: string
    authorityRange: E_AuthorityRange
}

export interface IAuthorityRecordBindListParams {
    userId: number
}

export interface IAuthorityRecordBindItem {
    id: number
    createTime: number
    checkStatus: E_CheckStatus
    authorityCharacter: string
    authorityName: string
}

export type IAuthorityRecordBindListData = IAuthorityRecordBindItem[]

export interface IAuthorityRecordBindUserParams {
    recordIds: number[]
    userId: number
}


export interface IAuthorityRecordDeleteParams {
    ids: number[]
}


export interface IAuthorityRecordGetAreaListParams {
    parentCompanyId: number
}

export interface IAreaVO {
    areaId: number
    areaName: string
    companyId: number
    companyName: string
}

export interface IAuthorityRecordAreaItem {
    areaVOS: IAreaVO[]
    companyId: number
    companyName: string
}

export type IAuthorityRecordGetAreaListData = IAuthorityRecordAreaItem[]

export interface IAuthorityRecordDetailParams {
    id: number
}


export interface IAuthorityRecordDetailData {
    areaListVOS: {
        areaVOS: IAreaVO[], companyId: number,
        companyName: string
    }[]
    authorityCharacter: string
    authorityName: string
    authorityRange: E_AuthorityRange
    id: number
}

export type IAuthorityRecordListParams = IPageParams & Partial<{
    authorityCharacter: string
    authorityName: string
    startTime: string
    endTime: string
}>

export interface IAuthorityRecorListItem {
    authorityCharacter: string
    authorityName: string
    authorityRange: E_AuthorityRange
    companyId: number
    createTime: string
    updateTime: string
    id: number
    parentCompanyId: number
    isDel: number
}

export type IAuthorityRecorListData = IPageData<IAuthorityRecorListItem>

export interface IAuthorityRecordUpdateParams extends IAuthorityRecordParamsAdd {
    id: number
}
