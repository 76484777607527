<template>
    <div class="wrap">
        <div class="no-data">{{ $t('errors.noData') }}</div>
    </div>
</template>
<script>
export default {
    name: "NoData",
    data() {
        return {

        }
    }
}
</script>
<style scoped>
.wrap {
    position: relative;
    width: 100%;
    height: 100%;
}

.no-data {
    position: absolute;
    width: 100px;
    height: 50px;
    left: 50%;
    margin-left: -50px;
    top: 50%;
    margin-top: -25px;
    text-align: center;
    font-size: 16px;
    line-height: 50px;
    color: rgba(255, 255, 255, 1);
}
</style>