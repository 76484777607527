/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmLevel } from "../enums";
import { IOptionStringItem, IPageData, IPageParams } from "../types";

/**
 * $t('enum.E_CustomThreshold.ISO')
 * $t('enum.E_CustomThreshold.Custom')
 */
export enum E_CustomThreshold {
    /**
     * 使用ISO
     */
    ISO = 0,
    /**
     * 自定义
     */
    Custom = 1,
}

export interface IThresholdSubItem {
    /**
         * 报警等级
         */
    alarmLevel: E_AlarmLevel
    /**
     * 速度X
     */
    vx: number
    /**
     * 速度Y
     */
    vy: number
    /**
     * 速度Z
     */
    vz: number
    /**
     * 峭度X
     */
    kurtX: number
    /**
     * 峭度Y
     */
    kurtY: number
    /**
     * 峭度Z
     */
    kurtZ: number
    /**
     * 波峰因数X
     */
    peakX: number
    /**
     * 波峰因数Y
     */
    peakY: number
    /**
     * 波峰因数Z
     */
    peakZ: number
    /**
     * 温度
     */
    tem: number
    /**
     * 电压
     */
    batt: number

    /**
     * 包络
     */
    envelopeX: number
    envelopeY: number
    envelopeZ: number
    /**
     * 高频Z轴加速度
     */
    azHigh: number
}

export interface IThresholdItem {
    id: number
    /**
     * 阈值名称
     */
    name: string
    /**
     * companyName
     */
    companyName: string
    /**
     * 报警等级集合
     */
    thresholdSubList: IThresholdSubItem[]

    /**
     * 下面还有些不需要
     */
    vzamax: number
}

export interface IThresholdListPageParams extends IPageParams {
    /**
     * 公司ID
     */
    companyId?: number
    name?: string
}


export type IThresholdListPageData = IPageData<IThresholdItem>

export interface IThresholdCreateParams {
    customThreshold: E_CustomThreshold
    /**
     * iso批准名称
     */
    isoType: string
    /**
     * 设备类型名称
     */
    param1: string
    /**
     * 轴承转速名称等.
     */
    param2: string

    /**
     * 电压
     */
    battCMax?: number;
    battDMax?: number;
    /**
     * 公司ID
     */
    companyId: number;
    /**
     * 峭度
     */
    kurtXAMax?: number;
    kurtXBMax?: number;
    kurtXCMax?: number;
    kurtXDMax?: number;
    kurtYAMax?: number;
    kurtYBMax?: number;
    kurtYCMax?: number;
    kurtYDMax?: number;
    kurtZAMax?: number;
    kurtZBMax?: number;
    kurtZCMax?: number;
    kurtZDMax?: number;
    /**
     * 阈值名称
     */
    name: string;
    /**
     * 波峰因数
     */
    peakXAMax?: number;
    peakXBMax?: number;
    peakXCMax?: number;
    peakXDMax?: number;
    peakYAMax?: number;
    peakYBMax?: number;
    peakYCMax?: number;
    peakYDMax?: number;
    peakZAMax?: number;
    peakZBMax?: number;
    peakZCMax?: number;
    peakZDMax?: number;
    /**
     * 温度
     */
    temAMax?: number;
    temBMax?: number;
    temCMax?: number;
    temDMax?: number;
    /**
     * 速度
     */
    vXAMax?: number;
    vXBMax?: number;
    vXCMax?: number;
    vXDMax?: number;
    vYAMax?: number;
    vYBMax?: number;
    vYCMax?: number;
    vYDMax?: number;
    vZAMax?: number;
    vZBMax?: number;
    vZCMax?: number;
    vZDMax?: number;
    /**
     * 包络
     */
    envelopeXAMax?: number
    envelopeXBMax?: number
    envelopeXCMax?: number
    envelopeXDMax?: number
    envelopeYAMax?: number
    envelopeYBMax?: number
    envelopeYCMax?: number
    envelopeYDMax?: number
    envelopeZAMax?: number
    envelopeZBMax?: number
    envelopeZCMax?: number
    envelopeZDMax?: number
    /**
     * 高频加速度
     */
    aZHighAMax?: number
    aZHighBMax?: number
    aZHighCMax?: number
    aZHighDMax?: number
}

export interface IThresholdEditParams extends IThresholdCreateParams {
    id: number
}

export interface IThresholdGetItem extends IThresholdCreateParams {

}

export type IThresholdIsoListData = IOptionStringItem[]

export type IThresholdIsoEquipmentListData = {
    IdevTypeEn: string
    isoSelectVOList: IOptionStringItem[]
}


export interface IThreadoldImportParams {
    file: File
}

export type IThreadoldExportParams = IThresholdListPageParams

export type IThresholdGetSensorByMappingIdData = IThresholdCreateParams