/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_CollapseItemStatus } from '../types'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'

import Up from '@/assets/image/wave/up.svg'
import Down from '@/assets/image/wave/down.svg'

@Component({
    name: 'CollapseArrow'
})
export class CollapseArrowImpl extends Vue {
    @Prop({
        type: String
    }) status: E_CollapseItemStatus

    get ArrowImage() {
        if (this.status === E_CollapseItemStatus.Full) {
            return Up
        } else {
            return Down
        }
    }

    onClick() {
        this.$emit('click')
    }
}
