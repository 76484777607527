/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import cn from './zh-CN.json'
import en from './en.json'

import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import ElementLocale from 'element-ui/lib/locale'
import { getLocale } from '@/config/locale'

Vue.use(VueI18n)

const messages = {
    cn: {
        ...cn,
        ...zhLocale,
    },
    en: {
        ...en,
        ...enLocale
    },
}

export function makeI18n() {
    const i18n = new VueI18n({
        locale: getLocale(),
        messages,
        fallbackLocale: 'cn',
        // missing: (locale, key, vm, value) => {
        //     if (locale === 'cn') {
        //         return 'Missing'
        //     } else {
        //         const res = i18n.t(key, 'cn', value)
        //         return `${locale}!${res}`
        //     }
        // }
    })
    ElementLocale.i18n((key, value) => i18n.t(key, value))
    return i18n
}
