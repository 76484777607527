/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IWaveSpectrumQuery } from '../../types'
import { ISamplingTimeData } from '@/api/moudules/wavefrom.types'
import { isFailedAndReport } from '@/api/helper'
import { formatDateTime, formatDateTimeBegin, formatDateTimeEnd } from '@/utils/datetime'
import { isCheckQueryFailedAndReport } from '@/view_helper/wave'
import { getAllMhfHzsBySensorType } from '@/view_helper/sensor'
import { E_ErrorCodes } from '@/api/errorCodes'
import { withFieldLoading, withLoading } from '@/utils/task'

interface ISamplingTimeDataEx extends ISamplingTimeData {
    checked: boolean
}

function makePageQuery() {
    return {
        pageNum: 1,
        pageSize: 16,
    }
}

@Component({
    name: 'FrequencyTimerFilter'
})
export class FrequencyTimerFilterImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    @Prop({
        type: Boolean,
        default: false,
    }) multiple: boolean

    @Prop({
        type: Array,
        default: () => []
    }) selectedWaveIds: number[]

    loading = false
    samplingTimeLoading = false
    sensorTypeLoading = false

    pageQuery = makePageQuery()

    samplingTimeIndex = 0
    samplingTimeTotal = 0
    samplingTimeList: ISamplingTimeData[] = []

    get HasSamplingTime() {
        return !_.isEmpty(this.samplingTimeList)
    }

    onSelectSamplingTime(value) {
        this.samplingTimeIndex = value
        this.$emit('select', this.samplingTimeList[this.samplingTimeIndex])
    }

    frequencyIndex = 0
    selectedSensorType = null

    showFrequencyList = true
    get FrequencyList() {
        if (!this.selectedSensorType) {
            return []
        }
        return getAllMhfHzsBySensorType(this.selectedSensorType)
    }

    changeFrequencyIndex(idx: number) {
        this.frequencyIndex = idx
        this.pageQuery.pageNum = 0
        this.onQuerySamplingTime()
    }

    handleCurrentChange(value: number) {
        this.pageQuery.pageNum = value
        this.onQuerySamplingTime()
    }

    get SamplingTimeExList() {
        const selectedSamplingSet = new Set(this.selectedWaveIds)
        return _.map(this.samplingTimeList, s => {
            return {
                ...s,
                checked: selectedSamplingSet.has(s.waveId)
            }
        })
    }

    // 选中事件列表
    changeTimeList(data: ISamplingTimeDataEx) {
        this.$emit('switch', data, !data.checked)
    }

    onQuery() {
        if (isCheckQueryFailedAndReport(this, this.query)) {
            return
        }
        this.frequencyIndex = -1
        this.selectedSensorType = null
        const task = this.$api.waveform.getSensorTypeByPointMapping(this.query.mappingId).then(res => {
            if(res.code === E_ErrorCodes.SensorNotExisted) {
                this.showFrequencyList = false
                return
            }            
            if (isFailedAndReport(this, res)) {
                return
            }
            this.showFrequencyList = true
            this.selectedSensorType = res.data
        })
        withFieldLoading(this, 'sensorTypeLoading', task)
        // this.$api.waveform.querySampleFrequency(this.query.mappingId).then(res => {
        //     if (isFailedAndReport(this, res)) {
        //         return
        //     }
        //     this.frequencyList = res.data || []
        // })
        this.onQuerySamplingTime()
    }

    onQuerySamplingTime() {
        const {
            mappingId,
            timeRange,
        } = this.query
        const {
            pageNum,
            pageSize,
        } = this.pageQuery
        let mhfHz = null
        if (!_.isEmpty(this.FrequencyList) && this.frequencyIndex !== -1) {
            mhfHz = this.FrequencyList[this.frequencyIndex].value
        }
        const task = this.$api.waveform.querySamplingTime({
            startTime: formatDateTimeBegin(timeRange[0]),
            endTime: formatDateTimeEnd(timeRange[1]),
            mappingId: String(mappingId),
            pageNum,
            pageSize,
            mhfHz,
        }).then(res => {
            if (isFailedAndReport(this, res)) {
                return
            }
            if (!res.data) {
                this.samplingTimeTotal = 0
                this.samplingTimeList = []
                return
            }
            this.samplingTimeTotal = res.data.total
            this.samplingTimeList = _.map(res.data.records || [], item => {
                return {
                    ...item,
                    mappingId: this.query.mappingId,
                    checked: false,
                }
            })
            if (!this.multiple && !_.isEmpty(this.samplingTimeList)) {
                this.onSelectSamplingTime(0)
            }
        })
        withFieldLoading(this, 'samplingTimeLoading', task)
    }

    onReset() {
        this.selectedSensorType = null
        this.frequencyIndex = 0
        this.samplingTimeTotal = 0
        this.samplingTimeList = []
    }
}
