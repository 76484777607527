/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import Vue from 'vue'
import { IMappingInfo } from "@/api/moudules/mapping.types";
import { ITitle } from '@/api/moudules/wave.types';
import { E_ChartTypeString } from '@/types';
import { IManyDataItem } from '@/api/moudules/wavefrom.types';
import { IWaveSpectrumQuery } from '@/views/wave/types';

export function formatTitle(mappingInfo: IMappingInfo) {
    if (!mappingInfo) {
        return ''
    }
    const {
        companyName,
        areaName,
        equipmenName,
        mappingName,
    } = mappingInfo
    return _.join([
        companyName,
        areaName,
        equipmenName,
        mappingName,
    ], '-')
}

export function formatTitle2(title: ITitle) {
    const {
        areaName,
        equipmentName,
        pointName,
        sensorId,
    } = title
    return _.join([
        areaName,
        equipmentName,
        pointName,
        sensorId,
    ], '-')
}

export function extratDataByType(chartType: E_ChartTypeString, data: IManyDataItem) {
    const {
        ay1,
        ay2,
        ay3,
        vy1,
        vy2,
        vy3,
        bfZ,
        sy1,
        sy2,
        sy3,
        qy1,
        qy2,
        qy3,
        bfY1,
        bfY2,
        bfY3,
        ey1,
        ey2,
        ey3,
        tem,
        rspeed,
    } = data
    switch (chartType) {
        case E_ChartTypeString.ACCELERATION:
            return [ay1, ay2, ay3]
        case E_ChartTypeString.VIBRATIO_INNTENSITY:
            return [vy1, vy2, vy3]
        case E_ChartTypeString.DISPLACEMENT:
            return [sy1, sy2, sy3]
        case E_ChartTypeString.TEMPERATURE:
            return [tem]
        case E_ChartTypeString.KURTOSIS:
            return [qy1, qy2, qy3]
        case E_ChartTypeString.CREST_FACTOR:
            return [bfY1, bfY2, bfY3]
        case E_ChartTypeString.ENVELOPE_DEMODULATION:
            return [ey1, ey2, ey3]
        case E_ChartTypeString.SPEED:
            return [rspeed]
    }
}

export function isCheckWaveIdFailedAndReport(vm: Vue, waveId: number) {
    if (_.isNil(waveId)) {
        vm.$message({
            type: 'error',
            message: vm.$t('management.selectWaveId') as string,
        })
        return true
    }
}

export function isCheckQueryFailedAndReport(vm: Vue, query: IWaveSpectrumQuery) {
    const {
        timeRange,
        mappingId
    } = query
    if (_.isNil(mappingId)) {
        vm.$message({
            type: 'error',
            message: vm.$t('common.selectMapping') as string,
        })
        return true
    }
    if (_.isNil(timeRange)) {
        vm.$message({
            type: 'error',
            message: vm.$t('common.selectTimeRange') as string,
        })
        return true
    }
    return false
}

/**
 * 波形图表分页选项
 */
export const WavePageOptions = Object.freeze([
    8192,
    16384,
    32768,
    65536,
])
