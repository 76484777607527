/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_CustomThreshold } from "@/api/moudules/threshold.types";
import { IPageData, IPageParams } from "@/api/types";

/**
 * 采集模式
 * $t('enum.E_InspectionStandardCollectMode.Eigenvalue')
 * $t('enum.E_InspectionStandardCollectMode.EigenvalueAndWave')
 */
export enum E_InspectionStandardCollectMode {
    /**
     * 特征值
     */
    Eigenvalue = 1,
    /**
     * 特征值和波形
     */
    EigenvalueAndWave = 2,
}


export interface IInspectionStandardItem {
    /**
     * 采集时长
     */
    collectLength: number
    collectMode: E_InspectionStandardCollectMode
    collectQty: number
    customThreshold: E_CustomThreshold
    isoParam1: number
    isoParam2: number
    isoType: string
    standardName: string
    thresholdId: number
    vibrateCx: number
    vibrateCy: number
    vibrateCz: number
    vibrateDx: number
    vibrateDy: number
    vibrateDz: number
    waveInterval: number
    companyId: number
}

export type IInspectionStandardAddParams = IInspectionStandardItem
export interface IInspectionStandardDetailData extends IInspectionStandardItem {
    id: number
}

export interface IInspectionStandardThresholdItem {
    id: number
    thresholdName: number
    vibrateCx: number
    vibrateCy: number
    vibrateCz: number
    vibrateDx: number
    vibrateDy: number
    vibrateDz: number
}

export type IInspectionStandardThresholdData = IInspectionStandardThresholdItem[]

export type IInspectionStandardListParams = IPageParams & Partial<{
    companyId: number
    isoParam1: number
    isoType: number
    standardName: number
}>

export interface IInspectionStandardListItem {
    id: number
    /**
     * 采集模式
     */
    collectMode: E_InspectionStandardCollectMode
    /**
     * 采集次数
     */
    collectQty: number
    customThreshold: E_CustomThreshold
    isoParam1: number
    isoParam2: number
    isoType: string
    /**
     * 标准名称
     */
    standardName: string
    thresholdId: number
    /**
     * 采集间隔
     */
    waveInterval: number
    collectLength: number
    companyId: number
    companyName: string


    vibrateCx: number
    vibrateCy: number
    vibrateCz: number
    vibrateDx: number
    vibrateDy: number
    vibrateDz: number
}

export type IInspectionStandardListData = IPageData<IInspectionStandardListItem>

export interface IInspectionStandardEditParams extends IInspectionStandardItem {
    id: number
}
