/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import Vue from "vue";
import { E_AlarmLevel, E_AlarmLevel2, E_AlarmType, E_DeviceStatus } from "@/api/enums";
import { E_AlarmMode, E_DealStatus } from "@/api/moudules/alarm.types";
import { E_AreaPicStatus, E_CompanyStatus } from "@/api/moudules/company.types";
import { E_BindSensorType, E_ConnectType_LoRa, E_DHCPModel, E_DataStatus, E_GateWayModel, E_GatewayStatus, E_GatewayType } from "@/api/moudules/gateway.types";
import { E_BusinessType, E_OperatorStatus, E_OperatorType } from "@/api/moudules/monitor.types";
import { E_OnlineOffline } from "@/api/moudules/overview.types";
import { E_MenuStatus, E_MenuType, E_MenuType__Invert } from "@/api/moudules/system.types";
import { E_ChartTypeString, E_Eigenvalue, E_FaultAlysisStatistics, E_Status, E_StatusString, E_StatusString__Invert } from "@/types";
import { E_EquipmentLevel } from "@/api/moudules/equipment.types";
import { E_LaunchState, E_ModelPartial } from "@/api/moudules/model.types";
import { E_CustomThreshold } from "@/api/moudules/threshold.types";
import { E_Sex, E_Sex__Invert } from "@/api/moudules/system";
import { E_CollectMode, E_CollectWork, E_InstallMethod, E_MHFFlag, E_SensorDirection, E_SensorType, E_StartStatus } from "@/api/moudules/sensor.types";
import { E_WaveTabs } from "@/views/wave/WaveSpectrumImpl";
import { E_ChartType } from "@/utils/chart";
import { E_DataSearchTableCol } from "@/view_helper/dataSearch";
import { E_WeeklyReportEquipmentStatus, E_WeeklyReportSeverityLevel } from '@/api/moudules/weeklyReport.types';
import { E_ErrorCodes } from '@/api/errorCodes';
import { E_Eigenvalue2 } from '@/api/moudules/eigenvalue.types';
import { E_AuthorityRange } from '@/api/moudules/authority.types';
import { E_EmailType } from '@/api/moudules/email.types';
import { E_InspectionStandardCollectMode } from '@/spot_check/api/modules/inspection/inspection_standard.types';
import { E_InspectionPointStatus, E_InspectionPointType } from '@/spot_check/api/modules/inspection/inspection_point.types';
import { E_AppUpdateType, E_AppVersionStatus } from '@/api/moudules/appver.types';
import { E_InspectStatus, E_InspectType, E_InspectionTaskStatus, E_InspectionYesNo, E_LineType } from '@/spot_check/api/modules/inspection/inspection_task.types';
import { E_AuditStatus, E_DangerLevel, E_DangerStatus, E_HandleStatus } from '@/spot_check/api/modules/inspection_danger/inspection_danger.types';
import { E_UpkeepHandleResult, E_UpkeepLevel } from '@/spot_check/api/modules/inspection_upkeep/inspection_upkeep.types';
import { E_FaultEffect, E_RepairLevel } from '@/spot_check/api/modules/inspection_repair/inspection_repair.types';
import { E_UpkeepRepairHandleStatus } from '@/spot_check/api/modules/types';
import { E_AlarmLampEquipmentType } from '@/api/moudules/alarmLamp.types';
import { E_UpgradeStatus } from '@/api/moudules/firmware.types';
import { E_AlarmLevelString, E_AlarmLevelString__Inverse } from '@/api/moudules/largeScreen.types';
import { E_CarrierReversal, E_DriveType } from '@/api/moudules/sensorNew.types';
import { E_ChannelType, E_CollectCardCollMode, E_CollectCardCoutingMode, E_CollectCardDirection, E_CollectCardTriggerDirection, E_ConstantCurrentSource, E_SelectChannel } from '@/api/moudules/collectCard.types';


export class EnumTrans {
    constructor(public vm: Vue) {
    }
    $t(key: string) {
        return this.vm.$t(key) as string
    }
    OnlineOffline = (value: E_OnlineOffline) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_OnlineOffline[value]
        return this.$t('enum.E_OnlineOffline.' + key)
    }
    AlarmType = (value: E_AlarmType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_AlarmType[value]
        return this.$t('enum.E_AlarmType.' + key)
    }
    AlarmType__Filter = (value: E_AlarmType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_AlarmType[value]
        return this.$t('enum.E_AlarmType__Filter.' + key)
    }
    AlarmLevel = (value: E_AlarmLevel) => {
        const key = E_AlarmLevel[value]
        return this.$t('enum.E_AlarmLevel.' + key)
    }
    AlarmLevel__long = (value: E_AlarmLevel) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_AlarmLevel[value]
        return this.$t('enum.E_AlarmLevel__long.' + key)
    }
    AlarmLevel2 = (value: E_AlarmLevel2) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_AlarmLevel2[value]
        return this.$t('enum.E_AlarmLevel2.' + key)
    }
    AlarmMode = (value: E_AlarmMode) => {
        const knownValue = [
            E_AlarmMode.Eigenvalue, E_AlarmMode.Wave,
        ]
        if (!knownValue.includes(value)) {
            return value
        }
        const key = E_AlarmMode[value]
        return this.$t('enum.E_AlarmMode.' + key)
    }
    DealStatus = (value: E_DealStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_DealStatus[value]
        return this.$t('enum.E_DealStatus.' + key)
    }
    GatewayStatus = (value: E_GatewayStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_GatewayStatus[value]
        return this.$t('enum.E_GatewayStatus.' + key)
    }
    CompanyStatus = (value: E_CompanyStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_CompanyStatus[value]
        return this.$t('enum.E_CompanyStatus.' + key)
    }
    MenuStatus = (value: E_MenuStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_MenuStatus[value]
        return this.$t('enum.E_MenuStatus.' + key)
    }
    /**
     * 设备状态     
     */
    DeviceStatus = (value: E_DeviceStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_DeviceStatus[value]
        return this.$t('enum.E_DeviceStatus.' + key)
    }

    Status = (value: E_Status) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_Status[value]
        return this.$t('enum.E_Status.' + key)
    }

    StatusString = (value: E_StatusString) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_StatusString__Invert[value]
        return this.$t('enum.E_StatusString.' + key)
    }

    /**
     * 设备等级     
     */
    EquipmentLevel = (value: E_EquipmentLevel) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_EquipmentLevel[value]
        return this.$t('enum.E_EquipmentLevel.' + key)
    }

    /**
     * 模型部件
     */
    ModelPartial = (value: E_ModelPartial) => {
        const key = E_ModelPartial[value]
        return this.$t('enum.E_ModelPartial.' + key)
    }

    /**
     * 网关类型
     */
    GatewayType = (value: E_GatewayType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_GatewayType[value]
        return this.$t('enum.E_GatewayType.' + key)
    }
    /**
     * 绑定传感器类型
     */
    BindSensorType = (value: E_BindSensorType) => {
        const key = E_BindSensorType[value]
        return this.$t('enum.E_BindSensorType.' + key)
    }

    ConnectType_LoRa = (value: E_ConnectType_LoRa) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_ConnectType_LoRa[value]
        return this.$t('enum.E_ConnectType_LoRa.' + key)
    }

    LaunchState = (value: E_LaunchState) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_LaunchState[value]
        return this.$t('enum.E_LaunchState.' + key)
    }

    CustomThreshold = (value: E_CustomThreshold) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_CustomThreshold[value]
        return this.$t('enum.E_CustomThreshold.' + key)
    }

    Sex = (value: E_Sex) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_Sex__Invert[value]
        return this.$t('enum.E_Sex.' + key)
    }

    MHFFlag = (value: E_MHFFlag) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_MHFFlag[value]
        return this.$t('enum.E_MHFFlag.' + key)
    }

    CollectWork = (value: E_CollectWork) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_CollectWork[value]
        return this.$t('enum.E_CollectWork.' + key)
    }

    CollectMode = (value: E_CollectMode) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_CollectMode[value]
        return this.$t('enum.E_CollectMode.' + key)
    }

    /**
     * 操作人类别     
     */
    OperatorType = (value: E_OperatorType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_OperatorType[value]
        return this.$t('enum.E_OperatorType.' + key)
    }

    OperatorStatus = (value: E_OperatorStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_OperatorStatus[value]
        return this.$t('enum.E_OperatorStatus.' + key)
    }

    /**
     * 业务操作类型     
     */
    BusinessType = (value: E_BusinessType) => {
        const key = E_BusinessType[value]
        return this.$t('enum.E_BusinessType.' + key)
    }

    /**
     * 菜单类型
     */
    MenuType = (value: E_MenuType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_MenuType__Invert[value]
        return this.$t('enum.E_MenuType.' + key)
    }

    /**
     * 图谱
     */
    WaveTabs = (value: E_WaveTabs) => {
        const key = E_WaveTabs[value]
        return this.$t('enum.E_WaveTabs.' + key)
    }

    /**
     * 图表类型
     */
    ChartType = (value: E_ChartType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_ChartType[value]
        return this.$t('enum.E_ChartType.' + key)
    }

    /**
     * 图例类型     
     */
    ChartTypeString = (value: E_ChartTypeString) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_ChartTypeString[value]
        return this.$t('enum.E_ChartTypeString.' + key)
    }

    /**
     * 
     */
    DataSearchTableCol = (value: E_DataSearchTableCol) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_DataSearchTableCol[value]
        return this.$t('enum.E_DataSearchTableCol.' + key)
    }

    SensorDirection = (value: E_SensorDirection) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_SensorDirection[value]
        return this.$t('enum.E_SensorDirection.' + key)
    }

    Eigenvalue = (value: E_Eigenvalue) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_Eigenvalue[value]
        return this.$t('enum.E_Eigenvalue.' + key)
    }

    /**
     * 安装方式     
     */
    InstallMethod = (value: E_InstallMethod) => {
        if (_.isNil(value)) {
            return
        }
        const key = value
        return this.$t('enum.E_InstallMethod.' + key)
    }
    /**
     * 诊断错误
     */
    FaultAlysisStatistics = (value: E_FaultAlysisStatistics) => {
        if (_.isNil(value)) {
            return
        }
        const key = value
        return this.$t('enum.E_FaultAlysisStatistics.' + key)
    }
    /**
     * 数据上云状态
     */
    DataStatus = (value?: E_DataStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_DataStatus[value]
        return this.$t('enum.E_DataStatus.' + key)
    }
    // 周报
    WeeklyReportEquipmentStatus = (value: E_WeeklyReportEquipmentStatus) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_WeeklyReportEquipmentStatus[value]
        return this.$t('enum.E_WeeklyReportEquipmentStatus.' + key)
    }
    WeeklyReportSeverityLevel = (value: E_WeeklyReportSeverityLevel) => {
        if (_.isNil(value)) {
            return value
        }
        const key = E_WeeklyReportSeverityLevel[value]
        return this.$t('enum.E_WeeklyReportSeverityLevel.' + key)
    }

    //错误码
    ErrorCodes = (value: E_ErrorCodes) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_ErrorCodes[value]
        return this.$t('enum.E_ErrorCodes.' + key)
    }

    Eigenvalue2 = (value: E_Eigenvalue2) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_Eigenvalue2[value]
        return this.$t('enum.E_Eigenvalue2.' + key)
    }
    AuthorityRange = (value: E_AuthorityRange) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_AuthorityRange[value]
        return this.$t('enum.E_AuthorityRange.' + key)
    }
    EmailType = (value: E_EmailType) => {
        if (_.isNil(value)) {
            return
        }
        const key = E_EmailType[value]
        return this.$t('enum.E_EmailType.' + key)
    }
    InspectionStandardCollectMode = (value: E_InspectionStandardCollectMode) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectionStandardCollectMode[value]
        return this.$t('enum.E_InspectionStandardCollectMode.' + key)
    }
    InspectionPointType = (value: E_InspectionPointType) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectionPointType[value]
        return this.$t('enum.E_InspectionPointType.' + key)
    }
    InspectionPointStatus = (value: E_InspectionPointStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectionPointStatus[value]
        return this.$t('enum.E_InspectionPointStatus.' + key)
    }
    AppUpdateType = (value: E_AppUpdateType) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AppUpdateType[value]
        return this.$t('enum.E_AppUpdateType.' + key)
    }
    AppVersionStatus = (value: E_AppVersionStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AppVersionStatus[value]
        return this.$t('enum.E_AppVersionStatus.' + key)
    }
    AreaPicStatus = (value: E_AreaPicStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AreaPicStatus[value]
        return this.$t('enum.E_AreaPicStatus.' + key)
    }
    InspectionYesNo = (value: E_InspectionYesNo) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectionYesNo[value]
        return this.$t('enum.E_InspectionYesNo.' + key)
    }
    InspectType = (value: E_InspectType) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectType[value]
        return this.$t('enum.E_InspectType.' + key)
    }
    LineType = (value: E_LineType) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_LineType[value]
        return this.$t('enum.E_LineType.' + key)
    }
    InspectionTaskStatus = (value: E_InspectionTaskStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectionTaskStatus[value]
        return this.$t('enum.E_InspectionTaskStatus.' + key)
    }
    /**
     * 巡检状态     
     */
    InspectStatus = (value: E_InspectStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_InspectStatus[value]
        return this.$t('enum.E_InspectStatus.' + key)
    }

    /**
     * 隐患单状态     
     */
    DangerStatus = (value: E_DangerStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_DangerStatus[value]
        return this.$t('enum.E_DangerStatus.' + key)
    }

    /**
     *  隐患等级 
     */
    DangerLevel = (value: E_DangerLevel) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_DangerLevel[value]
        return this.$t('enum.E_DangerLevel.' + key)
    }

    /**
     *  审核状态
     */
    AuditStatus = (value: E_AuditStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AuditStatus[value]
        return this.$t('enum.E_AuditStatus.' + key)
    }

    /**
     *  审核状态
     */
    AuditStatus2 = (value: E_AuditStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AuditStatus[value]
        return this.$t('enum.E_AuditStatus2.' + key)
    }



    HandleStatus = (value: E_HandleStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_HandleStatus[value]
        return this.$t('enum.E_HandleStatus.' + key)
    }

    UpkeepLevel = (value: E_UpkeepLevel) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_UpkeepLevel[value]
        return this.$t('enum.E_UpkeepLevel.' + key)
    }

    UpkeepRepairHandleStatus = (value: E_UpkeepRepairHandleStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_UpkeepRepairHandleStatus[value]
        return this.$t('enum.E_UpkeepRepairHandleStatus.' + key)
    }

    UpkeepHandleResult = (value: E_UpkeepHandleResult) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_UpkeepHandleResult[value]
        return this.$t('enum.E_UpkeepHandleResult.' + key)
    }

    RepairLevel = (value: E_RepairLevel) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_RepairLevel[value]
        return this.$t('enum.E_RepairLevel.' + key)
    }

    FaultEffect = (value: E_FaultEffect) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_FaultEffect[value]
        return this.$t('enum.E_FaultEffect.' + key)
    }
    AlarmLampEquipmentType = (value: E_AlarmLampEquipmentType) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_AlarmLampEquipmentType[value]
        return this.$t('enum.E_AlarmLampEquipmentType.' + key)
    }
    StartStatus = (value: E_StartStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_StartStatus[value]
        return this.$t('enum.E_StartStatus.' + key)
    }
    UpgradeStatus = (value: E_UpgradeStatus) => {
        if (_.isNil(value)) {
            return ''
        }
        const key = E_UpgradeStatus[value]
        return this.$t('enum.E_UpgradeStatus.' + key)
    }
    SensorType = (value: E_SensorType) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_SensorType[value]
        return this.$t('enum.E_SensorType.' + key)
    }
    AlarmLevelString = (value: E_AlarmLevelString) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_AlarmLevelString__Inverse[value]
        return this.$t('enum.E_AlarmLevelString.' + key)
    }
    DriveType = (value: E_DriveType) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_DriveType[value]
        return this.$t('enum.E_DriveType.' + key)
    }
    
    CollectCardCoutingMode = (value: E_CollectCardCoutingMode) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_CollectCardCoutingMode[value]
        return this.$t('enum.E_CollectCardCoutingMode.' + key)
    }
    CollectCardDirection = (value: E_CollectCardDirection) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_CollectCardDirection[value]
        return this.$t('enum.E_CollectCardDirection.' + key)
    }
    SelectChannel = (value: E_SelectChannel) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_SelectChannel[value]
        return this.$t('enum.E_SelectChannel.' + key)
    }
    CollectCardCollMode = (value: E_CollectCardCollMode) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_CollectCardCollMode[value]
        return this.$t('enum.E_CollectCardCollMode.' + key)
    }
    CollectCardTriggerDirection = (value: E_CollectCardTriggerDirection) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_CollectCardTriggerDirection[value]
        return this.$t('enum.E_CollectCardTriggerDirection.' + key)
    }
    ConstantCurrentSource = (value: E_ConstantCurrentSource) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_ConstantCurrentSource[value]
        return this.$t('enum.E_ConstantCurrentSource.' + key)
    }    
    ChannelType = (value: E_ChannelType) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_ChannelType[value]
        return this.$t('enum.E_ChannelType.' + key)
    }
    CarrierReversal = (value: E_CarrierReversal) => {
        if(_.isNil(value)) {
            return undefined
        }
        const key = E_CarrierReversal[value]
        return this.$t('enum.E_CarrierReversal.' + key)
    }
    GateWayModel = (value: E_GateWayModel) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_GateWayModel[value]
        return this.$t('enum.E_GateWayModel.' + key)
    }
    DHCPModel = (value: E_DHCPModel) => {
        if (_.isNil(value)) {
            return undefined
        }
        const key = E_DHCPModel[value]
        return this.$t('enum.E_DHCPModel.' + key)
    }
}
