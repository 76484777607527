/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Prop, } from 'vue-property-decorator'

@Component({
    name: 'LeftRight',
})
export class LeftRightImpl extends Vue {
    @Prop({
        type: String
    }) leftTooltip: string

    @Prop({
        type: String
    }) rightTooltip: string

    @Prop({
        type: Boolean,
        default: false,
    }) disabledLeft: boolean

    @Prop({
        type: Boolean,
        default: false,
    }) disabledRight: boolean

    //#region Left
    onClickLeft() {
        if (this.disabledLeft) { return }
        this.$emit('clickleft')
    }
    tooltipLeft = false
    mouseEnterLeft() {
        if (this.disabledLeft) { return }
        this.tooltipLeft = true
    }
    mouseLeaveLeft() {
        this.tooltipLeft = false
    }
    //#endregion

    //#region Right
    onClickRight() {
        if (this.disabledRight) { return }
        this.$emit('clickright')
    }
    tooltipRight = false
    mouseEnterRight() {
        if (this.disabledRight) { return }
        this.tooltipRight = true
    }
    mouseLeaveRight() {
        this.tooltipRight = false
    }

    //#endregion
}
