/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "@/api/types"

/**
 * 隐患等级
 * $t('enum.E_DangerLevel.First')
 * $t('enum.E_DangerLevel.Second')
 * $t('enum.E_DangerLevel.Third')
 */
export enum E_DangerLevel {
    /**
     * 一级非常严重
     */
    First = 1,
    /**
     * 二级严重
     */
    Second = 2,
    /**
     * 三级轻微
     */
    Third = 3
}

/**
 * 隐患单状态
 * $t('enum.E_DangerStatus.PendingApproval')
 * $t('enum.E_DangerStatus.PendingDetected')
 * $t('enum.E_DangerStatus.Settled')
 */
export enum E_DangerStatus {
    /**
     * 待分配
     */
    PendingApproval = 1,
    /**
     * 待检测
     */
    PendingDetected = 2,
    /**
     * 已结单
     */
    Settled = 3,
}

/**
 * 审核状态
 * $t('enum.E_AuditStatus.PendingReview')
 * $t('enum.E_AuditStatus.Reviewed')
 * $t('enum.E_AuditStatus.Reject')
 * 
 * $t('enum.E_AuditStatus2.Reviewed')
 * $t('enum.E_AuditStatus2.Reject')
 */
export enum E_AuditStatus {
    /**
     * 待审核
     */
    PendingReview = 1,
    /**
     * 审核完成
     */
    Reviewed = 2,
    /**
     * 驳回
     */
    Reject = 3,
}

/**
 * 处理状态
 * $t('enum.E_HandleStatus.Handling')
 * $t('enum.E_HandleStatus.Handled')
 * $t('enum.E_HandleStatus.Closed')
 */
export enum E_HandleStatus {
    /**
     * 处理中
     */
    Handling = 1,
    /**
     * 处理完成
     */
    Handled = 2,
    /**
     * 关闭
     */
    Closed = 3,
}

export interface IDangerItem {
    companyId: number
    areaId: number
    equipmentId: number
    inspectTaskId: number
    createdId: number
    dangerDesc: string
    /**
     * 隐患等级
     */
    dangerLevel: E_DangerLevel
    /**
     * 隐患库
     */
    dangerLibId: number
    dangerName: string
    dangerReason: string
    dangerSolution: string
    userId: number[]
}

export type IDangerAddParams = IDangerItem

export interface IDangerAddHandleParams {
    id: number
    userId: number
}

export interface IDangerDetailParams {
    id: number
}

export interface IHandleDetail {
    assignTime: string
    auditRemark: string
    auditStatus: E_AuditStatus
    file1: string
    file2: string
    file3: string
    handleDesc: string
    handleTime: string
    id: number
    img1FileId: number
    img2FileId: number
    img3FileId: number
    status: E_HandleStatus
    userName: string
}

export interface IDangerDetailData {
    areaName: string
    companyName: string
    companyId: number
    createName: string
    dangerLibId: number
    dangerLibName: string
    dangerDesc: string
    dangerLevel: E_DangerLevel
    dangerName: string
    dangerReason: string
    dangerSolution: string
    equipmentId: number
    equipmentName: number
    file1: string
    file2: string
    file3: string
    id: number
    img1FileId: number
    img2FileId: number
    img3FileId: number
    inspectTaskId: number    
    handleDetail: IHandleDetail[]
}

export type IDangerPageListParams = IPageParams & Partial<{
    areaId: number
    companyId: number
    userId: number
}>

export interface IDangerPageListItem {
    areaName: string
    companyId: number
    companyName: string
    /**
     * 上报人
     */
    createName: string
    dangerName: string
    dangerSn: string
    handleTime: string
    id: number
    /**
     * 解决人
     */
    solveId?: number
    solveName: string
    status: E_DangerStatus
}

export type IDangerPageListData = IPageData<IDangerPageListItem>

export interface IDangerHandleItem {
    /**
             * 审核备注
             */
    auditRemark?: string
    auditStatus?: E_AuditStatus
    id?: number
    /**
     * 处理人
     */
    userId?: number
}


export interface IDangerUpdateParams {
    id: number
    dangerLibId: number
    dangerDesc: string
    dangerReason: string
    dangerSolution: string
    handleVos: IDangerHandleItem[]
}
