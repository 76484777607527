/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

/**
 * 时间单位
 */
export enum E_TimeUnit {
    Second = 'S',
    Minute = 'M',
    Hour = 'H',
    Day = 'D',

}

export function toSeconds(value: number, unit: E_TimeUnit) {
    const table = {
        [E_TimeUnit.Second]: 1,
        [E_TimeUnit.Minute]: 60,
        [E_TimeUnit.Hour]: 60 * 60,
        [E_TimeUnit.Day]: 60 * 60 * 24,
    }
    return table[unit] * value
}

export function toMinute(value: number, unit: E_TimeUnit) {
    const table = {
        [E_TimeUnit.Second]: 1 / 60,
        [E_TimeUnit.Minute]: 1,
        [E_TimeUnit.Hour]: 60,
        [E_TimeUnit.Day]: 60 * 24,
    }
    return table[unit] * value
}

export enum E_DateUnit {
    Day = 'Day',
    Month = 'Month',
    Year = 'Year',
}

export function toDays(value: number, unit: E_DateUnit) {
    const table = {
        [E_DateUnit.Day]: 1,
        [E_DateUnit.Month]: 30,
        [E_DateUnit.Year]: 365,
    }
    return table[unit] * value
}
