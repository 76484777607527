/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { TaskSource } from "./task"

/**
 * 弹出框
 */
export enum E_DialogStage {
    View = 'View',
    Create = 'Create',
    CreateFromCopy = 'CreateFromCopy',
    Edit = 'Edit',
    /**
     * 创建子项目
     * (ex: 子菜单，子公司)
     */
    CreateSubItem = 'CreateSubItem',
    /**
     * 分配
     */
    Assign = 'Assign',
}

export namespace DialogStage {
    export function IsCreateLike(stage: E_DialogStage) {
        return stage === E_DialogStage.Create || stage === E_DialogStage.CreateFromCopy
    }
}

/**
 * 弹出框结果
 */
export enum E_DialogResult {
    Confirm = 'Confirm',
    Cancel = 'Cancel',
    Close = 'Close',
}

export function makeDialogContext() {
    let task = new TaskSource<E_DialogResult>()
    function onShow(ctx) {
        task = new TaskSource<E_DialogResult>()
        ctx.show = true
    }
    function onHiden(ctx, reason: E_DialogResult) {
        ctx.show = false
        task.Resolve(reason)
    }
    return {
        show: false,
        onShow,
        onHiden,
        onClose(ctx) {
            onHiden(ctx, E_DialogResult.Close)
        },
        onCancel(ctx) {
            onHiden(ctx, E_DialogResult.Cancel)
        },
        onConfirm(ctx) {
            onHiden(ctx, E_DialogResult.Confirm)
        },
        Promise() {
            return task.Promise
        }
    }
}
