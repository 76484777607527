/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'
import moment from 'moment';
import NoData from "@/components/NoData/index.vue";
import { IOptionNumberItem } from '@/api/types';
import { IWaveListParams } from '@/api/moudules/wave.types';
import { formatDateTime, formatDateTimeBegin, formatDateTimeEnd } from '@/utils/datetime';

export interface ISelectedData {
    companyId: number
    areaId: number
    equipmentId: number
    mappingId: number
    time?: any
}

@Component({
    name: 'DataRetrieval',
    components: {
        NoData,
    }
})
export class DataRetrievalImpl extends Vue {
    @Prop({
        type: Boolean
    })
    dialogShow: boolean // 是否展示

    @Prop({
        type: Number
    })
    mappingId: number // 测点id

    @Prop({
        type: String
    })
    minTime: string // 开始时间

    @Prop({
        type: String
    })
    maxTime: string // 结束时间

    @Prop({
        type: Boolean,
        default: false,
    }) useTime: boolean

    @Prop({
        type: Boolean,
        default: true,
    }) useTimeList: boolean

    @Prop({
        type: Object,
    }) selectedData: ISelectedData

    get SelectedData() {
        if (!this.selectedData) {
            return {} as ISelectedData
        }
        return this.selectedData
    }

    // 鼠标是否在弹窗上
    isFirst = true // 是否第一次进来

    orgList: IOptionNumberItem[] = [] // 公司列表
    areaList: IOptionNumberItem[] = []// 区域列表
    deviceList: IOptionNumberItem[] = [] // 设备列表
    pointList: IOptionNumberItem[] = [] // 测点列表
    defaultOrg = null// 默认值

    startTime = moment().format('YYYY-MM-DD HH:mm:ss') // 开始时间
    endTime = moment().format('YYYY-MM-DD HH:mm:ss') // 结束时间
    time = null

    timeDataShow = false // 时间列表是否展示

    activeCompaneyIndex = -1
    activeAreaIndex = -1
    activeDeviceIndex = -1
    activePointIndex = -1
    activeTimeIndex = -1 // 当前选中
    activeCompaney = null
    activeArea = null
    activeDevice = null
    activePoint = null
    activeTime = "" // 当前选中时间

    page = {
        pageNum: 1,
        pageSize: 60,
        total: 0,
    }

    timeData = []

    get TimeData() {
        const {
            pageNum,
            pageSize,
        } = this.page
        const start = pageNum * pageSize
        const end = start + pageSize
        return _.slice(this.timeData, start, end)
    }

    open(e) {
        e.stopPropagation();
        this.$emit("open", true);
    }
    choose(item, i) {
        this.activeTimeIndex = i;
        this.activeTime = item.createTime;
    }
    cancel() {
        this.$emit("cancel", this.buildSelectedData());
    }

    buildSelectedData(): ISelectedData {
        const getValue = x => {
            if (x) {
                return x.value
            }
            return undefined
        }
        const selectedData: ISelectedData = {
            companyId: getValue(this.activeCompaney),
            areaId: getValue(this.activeArea),
            equipmentId: getValue(this.activeDevice),
            mappingId: getValue(this.activePoint),
            time: _.clone(this.time),
        }
        return selectedData
    }

    confirm() {
        if (this.useTimeList) {
            if (_.isEmpty(this.timeData)) {
                this.$message({
                    message: this.$t('errors.selectTimeData') as any,
                    type: 'warning'
                });
                return;
            }

            this.$emit("confirm", this.activeTimeIndex, this.timeData, this.buildSelectedData());
        } else {
            if (this.useTime) {
                if (_.isEmpty(this.time)) {
                    this.$message({
                        message: this.$t('errors.selectTimeRange') as any,
                        type: 'warning'
                    });
                    return;
                }
            }
            if (_.isEmpty(this.pointList)) {
                this.$message({
                    message: this.$t('errors.selectMapping') as any,
                    type: 'warning'
                });
                return;
            }
            this.$emit('confirm', this.pointList[this.activePointIndex], this.buildSelectedData())
        }
    }

    handleChange() {
        this.$emit("handleChange", false);
    }

    onDateChange(value) {
        if (value) {            
            this.startTime = formatDateTimeBegin(value[0])
            this.endTime = formatDateTimeEnd(value[1])
        } else {
            this.startTime = null
            this.endTime = null
        }
        this.fetchTimeTable(this.activePoint)
    }

    // 请求公司
    fetchCompaney() {
        return this.$api.company.getCompanyList().then((res) => {
            const data = res.data;
            if (!_.isEmpty(res.data)) {
                this.orgList = data
                this.activeCompaney = data[0]
                this.activeCompaneyIndex = 0
                if (this.isFirst) {
                    let index = _.findIndex(this.orgList, o => o.value === this.SelectedData.companyId)
                    if (index >= 0) {
                        this.activeCompaney = data[index]
                        this.activeCompaneyIndex = index
                    }
                }
                return this.fetchArea(this.activeCompaney)
            }
        });
    }
    // 请求区域
    fetchArea(item: IOptionNumberItem) {
        this.areaList = [];
        this.timeData = [];
        return this.$api.area.getAreaListByCompanyId(item.value).then((res) => {
            if (!_.isEmpty(res.data)) {
                this.areaList = res.data
                this.activeArea = res.data[0]
                this.activeAreaIndex = 0
                if (this.isFirst) {
                    let index = _.findIndex(this.areaList, o => o.value === this.SelectedData.areaId)
                    if (index >= 0) {
                        this.activeArea = res.data[index]
                        this.activeAreaIndex = index
                    }
                }
                return this.fetchDevice(res.data[0])
            }
        });
    }
    // 请求设备
    fetchDevice(item: IOptionNumberItem) {
        this.deviceList = [];
        this.timeData = [];
        return this.$api.equipment.getEquipmentListByAreaId(item.value).then((res) => {
            if (!_.isEmpty(res.data)) {
                this.deviceList = res.data
                this.activeDevice = res.data[0]
                this.activeDeviceIndex = 0
                if (this.isFirst) {
                    let index = _.findIndex(this.deviceList, o => o.value === this.SelectedData.equipmentId)
                    if (index >= 0) {
                        this.activeDevice = res.data[index]
                        this.activeDeviceIndex = index
                    }
                }
                return this.fetchPoint(this.activeDevice)
            }
        });
    }
    // 请求测点
    fetchPoint(item: IOptionNumberItem) {
        this.pointList = [];
        this.timeData = [];
        return this.$api.mapping.getMappingOptions(item.value).then((res) => {
            if (!_.isEmpty(res.data)) {
                this.pointList = res.data
                this.activePoint = res.data[0]
                this.activePointIndex = 0
                if (this.isFirst) {
                    let index = _.findIndex(this.pointList, o => o.value === this.SelectedData.mappingId)
                    if (index >= 0) {
                        this.activePoint = res.data[index]
                        this.activePointIndex = index
                    }
                }
                if (this.useTimeList) {
                    this.page = {
                        pageNum: 1,
                        pageSize: 60,
                        total: 0,
                    }
                    this.fetchTimeTable(this.activePoint)
                }
            }
        });
    }

    // 请求时间列表
    fetchTimeTable(item: IOptionNumberItem) {
        if (!this.useTimeList) {
            return
        }
        this.timeData = [];
        const {
            startTime,
            endTime,
        } = this
        const {
            pageNum,
            pageSize,
        } = this.page
        const params: IWaveListParams = {
            pageNum,
            pageSize,
            mappingId: item.value
        }
        if (startTime && endTime) {
            params.beginTime = startTime
            params.endTime = endTime
        }
        this.$api.wave.waveList(params).then(res => {
            const {
                records,
                total
            } = res.data
            this.page.total = total
            if (!_.isEmpty(records)) {
                this.timeData = records
                this.timeDataShow = true
                this.activeTime = records[0].createTime
                this.activeTimeIndex = 0
            }
        })
    }

    // 组织
    chooseOrg(item, i) {
        this.isFirst = false;
        this.activeCompaneyIndex = i;
        this.activeCompaney = item;
        this.areaList = [];
        this.deviceList = [];
        this.pointList = [];
        this.fetchArea(item);
    }

    chooseArea(item, i) {
        this.isFirst = false;
        this.activeAreaIndex = i;
        this.activeArea = item;
        this.deviceList = [];
        this.pointList = [];
        this.fetchDevice(item);
    }

    chooseDevice(item, i) {
        this.isFirst = false;
        this.activeDeviceIndex = i;
        this.activeDevice = item;
        this.pointList = [];
        this.fetchPoint(item);
    }
    choosePoint(item, i) {
        this.isFirst = false;
        this.activePointIndex = i;
        this.activePoint = item;
        this.page = {
            pageNum: 1,
            pageSize: 60,
            total: 0,
        };
        this.fetchTimeTable(this.activePoint);
    }

    // 查询时间列表
    search() {
        this.isFirst = false;
        this.page = {
            pageNum: 1,
            pageSize: 60,
            total: 0,
        };
        this.fetchTimeTable(this.activePoint);
    }

    // 开始时间失去焦点
    startTimeBlur() {
        if (moment(this.startTime).isValid()) {
            this.startTime = moment(this.startTime).format('YYYY-MM-DD HH:mm:ss');
        } else {
            this.startTime = moment().format('YYYY-MM-DD HH:mm:ss');
        }
    }

    // 结束时间失去焦点
    endTimeBlur() {
        if (moment(this.endTime).isValid()) {
            this.endTime = moment(this.endTime).format('YYYY-MM-DD HH:mm:ss');
        } else {
            this.endTime = moment().format('YYYY-MM-DD HH:mm:ss');
        }
    }

    // 页数改变
    handleSizeChange(val) {
        this.page.pageSize = val;
        this.fetchTimeTable(this.activePoint);
    }
    // 当前页
    handleCurrentChange(val) {
        this.page.pageNum = val;
        this.fetchTimeTable(this.activePoint);
    }


    created() {
        if (null != this.minTime) {
            this.startTime = this.minTime;
        }
        if (null != this.maxTime) {
            this.endTime = this.maxTime;
        }
        // 查询默认值
        this.isFirst = true
        this.fetchCompaney().finally(() => {
            this.isFirst = false
        })
        if (this.SelectedData.time) {
            this.time = _.clone(this.SelectedData.time)
            this.onDateChange(this.time)
        }
    }
}
