/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { Ref } from "vue"

export function withLoading<T extends { loading: boolean }, R>(instance: T, task: Promise<R>) {
    instance.loading = true
    return task.finally(() => {
        instance.loading = false
    })
}

export function withFieldLoading<T, R>(instance: T, field: string, task: Promise<R>) {
    instance[field] = true
    return task.finally(() => {
        instance[field] = false
    })
}

export function toBoolPromise<T>(p: Promise<T>) {
    return p.catch(e => {
        console.error(e)
        return false
    })
}

export function withDialogLoading<R>(vm: Vue, task: Promise<R>) {
    const loading = vm.$loading({
        target: vm.$el.querySelector('.el-dialog') as HTMLElement
    })
    return task.finally(() => {
        loading.close()
    })
}

export function sleep(ms: number) {
    return new Promise((res, _rej) => {
        setTimeout(() => {
            res(undefined)
        }, ms)
    })
}

export class TaskSource<T = any> {
    _promise: Promise<T>
    _resolve
    _reject
    constructor() {
        this._promise = new Promise((resolve, reject) => {
            this._resolve = resolve
            this._reject = reject
        })
    }
    get Promise() {
        return this._promise
    }
    Resolve(value: T = undefined) {
        this._resolve(value)
    }
    Reject(err) {
        this._reject(err)
    }
}


export function withRefLoading<T>(loading: Ref<boolean>, task: Promise<T>) {
    loading.value = true
    return task.finally(() => {
        loading.value = false
    })
}
