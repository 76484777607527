/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "@/api/types"

/**
 * $t('enum.E_InspectionPointType.PDA')
 * $t('enum.E_InspectionPointType.QRCode')
 * $t('enum.E_InspectionPointType.NFC')
 */
export enum E_InspectionPointType {
    /**
     * PDA
     */
    PDA = 0,
    /**
     * 二维码
     */
    QRCode = 1,
    /**
     * NFC
     */
    NFC = 2,
}

/**
 * $t('enum.E_InspectionPointStatus.On')
 * $t('enum.E_InspectionPointStatus.Off')
 */
export enum E_InspectionPointStatus {
    On = 0,
    Off = 1,
}

export interface IInspectionPointItem {
    /**
     * 横坐标
     */
    abscissa: number
    /**
     * 纵坐标
     */
    ordinate: number
    areaId: number
    companyId: number
    equipmentId: number
    /**
     * 标识编号
     */
    identify: string
    imgId

    pointType: E_InspectionPointType
    /**
     * 备注
     */
    remark: string
}

export type IInspectionPointAddParams = IInspectionPointItem

export interface IInspectionPointDetailData extends IInspectionPointItem {
    id: number
}

export type IInspectionPointPageListParams = IPageParams & Partial<{
    companyId: number
    areaId: number
    equipmentId: number
    pointStatus: E_InspectionPointStatus
}>

export interface IInspectionPointPageListItem {
    /**
     * 横坐标
     */
    abscissa: number
    /**
     * 纵坐标
     */
    ordinate: number
    areaName: string,
    companyName: string
    equipmentName: string
    id: number
    /**
     * 标识编号
     */
    identify: string
    /**
     * 状态
     */
    pointStatus: E_InspectionPointStatus
    /**
     * 巡检点类型
     */
    pointType: E_InspectionPointType
}

export type IInspectionPointPageListData = IPageData<IInspectionPointPageListItem>

export interface IInspectionPointUpdateParams extends IInspectionPointItem {
    id: number
}

export interface IInspectionPointUpdateStatusParams {
    id: number
    pointStatus: E_InspectionPointStatus
}

export interface IInspectionPointUploadAreaImgParams {
    areaId: number
    imgId: number
    /**
     * 厂区图片备注
     */
    imgRemark: string
}

export interface IInspectionPointAreaImgDetailData extends IInspectionPointUploadAreaImgParams {
    fileUrl: string
    areaName: string
    companyName: string
}
