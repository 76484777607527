/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * $t('enum.E_EmailType.Alaram')
 * $t('enum.E_EmailType.Weekly') 
 * $t('enum.E_EmailType.Monthly')
 * * $t('enum.E_EmailType.Inspection')
 * 
 * $t('enum.E_EmailType2.Alaram')
 * $t('enum.E_EmailType2.Weekly')
 * $t('enum.E_EmailType2.Inspection')
 */
export enum E_EmailType {
    /**
     * 报警邮件
     */
    Alaram = 1,
    /**
     * 周报邮件
     */
    Weekly = 2,    
    /**
     * 月报邮件
     */
    Monthly = 4,
    /**
     * 点检周报
     */
    Inspection = 5,
}

export interface IReceiveItem {
    /**
     * 收件人邮件
     */
    receiveEmail: string
    /**
     * 收件人账号Id
     */
    userId: number
    /**
     * 收件人账号名称
     */
    userName: string
}

export interface IEmailAddTemplateParams {
    /**
     * 邮件模板名称
     */
    emailName: string
    /**
     * 邮件标题
     */
    emailTitle: string
    /**
     * 
     */
    emailType: E_EmailType
    /**
     * 发件人邮件
     */
    sendEmail: string
    /**
     * 发送的服务器
     */
    sendHost: string
    /**
     * 发件人邮件密码或授权码
     */
    sendPassword: string
    /**
     * 发送的服务器端口
     */
    sendPort: number
    /**
     * 收件人信息
     */
    receiveVos: IReceiveItem[]
    companyId: number
}


export interface IEmailGetTemplateDetailData extends IEmailAddTemplateParams {
    id: number
}

export type IEmailGetEmailAccountListData = IReceiveItem[]

export type IEmailPageListParams = IPageParams & Partial<{
    /**
     * 操作人员
     */
    createName: string
    /**
     * 邮件名称
     */
    emailName: string
    /**
     * 
     */
    emailType: E_EmailType
}>

export interface IEmailPageListItem {
    /**
     * 操作人员
     */
    createName: string
    /**
     * 
     */
    createTime: string
    /**
     * 邮件名称
     */
    emailName: string
    /**
     * 邮件类型
     */
    emailType: E_EmailType
    id: number
    /**
     * 收件人数量
     */
    receiveNum: number
}

export type IEmailPageListData = IPageData<IEmailPageListItem>

export interface IEmailUpdateTemplateParams extends IEmailAddTemplateParams {
    id: number
}
