/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types";

/**
 * 操作状态
 * $t('enum.E_OperatorStatus.Normal')
 * $t('enum.E_OperatorStatus.Exception')
 */
export enum E_OperatorStatus {
    /**
     * 正常
     */
    Normal = 0,
    /**
     * 异常
     */
    Exception = 1,
}

/**
 * 业务操作类型
 * $t('enum.E_BusinessType.Other')
 * $t('enum.E_BusinessType.Insert')
 * $t('enum.E_BusinessType.Update')
 * $t('enum.E_BusinessType.Delete')
 * $t('enum.E_BusinessType.Grant')
 * $t('enum.E_BusinessType.Export')
 * $t('enum.E_BusinessType.Import')
 * $t('enum.E_BusinessType.Force')
 * $t('enum.E_BusinessType.Gencode')
 * $t('enum.E_BusinessType.Clean')
 */
export enum E_BusinessType {
    /**
     * 其它
     */
    Other = 0,
    /**
     * 新增
     */
    Insert = 1,
    /**
     * 修改
     */
    Update = 2,
    /**
     * 删除
     */
    Delete = 3,
    /**
     * 授权
     */
    Grant = 4,
    /**
     * 导出
     */
    Export = 5,
    /**
     * 导入
     */
    Import = 6,
    /**
     * 强退
     */
    Force = 7,
    /**
     * 生成代码
     */
    Gencode = 8,
    /**
     * 清空数据
     */
    Clean = 9,
}

/**
 * 操作人类别
 * $t('enum.E_OperatorType.Other')
 * $t('enum.E_OperatorType.Backend')
 * $t('enum.E_OperatorType.Mobile')
 */
export enum E_OperatorType {
    /**
     * 其它
     */
    Other = 0,
    /**
     * 后台用户
     */
    Backend = 1,
    /**
     * 手机端用户
     */
    Mobile = 2,
}

export interface IOperlogListParams extends IPageParams {
    title?: string
    operName?: string
    businessType?: E_BusinessType
    status?: E_OperatorStatus
    beginTime?: string
    endTime?: string
}

export interface IOperlogListItem {
    /**
     * 
     */
    id: number
    /**
     * 模块标题
     */
    title: string
    /**
     * 业务类型
     */
    businessType: E_BusinessType
    /**
     * 方法名称
     */
    method: string
    /**
     * 请求方式
     */
    requestMethod: string
    /**
     * 操作类别
     */
    operatorType: E_OperatorStatus
    /**
     * 操作人员
     */
    operName: string
    /**
     * 部门名称
     */
    deptName: string
    /**
     * 请求URL
     */
    operUrl: string
    /**
     * 主机地址
     */
    operIp: string
    /**
     * 操作地点
     */
    operLocation: string
    /**
     * 请求参数
     */
    operParam: string
    /**
     * 返回参数
     */
    jsonResult: string
    /**
     * 操作状态
     */
    status: E_OperatorStatus
    /**
     * 错误消息
     */
    errorMsg: string
    /**
     * 操作时间
     */
    operTime: string
}

export type IOperlogListData = IPageData<IOperlogListItem>


export interface IOperlogLoginListParams extends IPageParams {

}

export interface IOperlogLoginListItem {
    browser: string
    id: number
    ipaddr: string
    loginLocation: string
    loginTime: string
    msg: string
    os: string
    status: E_OperatorStatus
    userName: string
}

export type IOperlogLoginListData = IPageData<IOperlogLoginListItem>
