/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_AlarmLevel, E_AlarmType, E_DeviceStatus } from "@/api/enums";
import { IAlarmTypeVOData } from "@/api/moudules/sensorNew.types";
import { E_TimeUnit, toMinute } from "@/utils/time";
import _ from "lodash";

export const AllAlarmLevels = [
  E_AlarmLevel.Normal,
  E_AlarmLevel.Offline,
  E_AlarmLevel.C,
  E_AlarmLevel.D,
]

/**
 * 设备状态过滤项
 */
export const DeviceStatusList = Object.freeze([
  E_DeviceStatus.Normal,
  E_DeviceStatus.C,
  E_DeviceStatus.D,
  E_DeviceStatus.Offline,
])


/**
 * 设备状态过滤项+重点关注
 */
export const DeviceStatusWithFocusOnList = Object.freeze([
  ...DeviceStatusList,
  E_DeviceStatus.FocusOn,
])

/**
 * 默认报警配置
 */
export const DefualtAlarmConfigs: Record<string, IAlarmTypeVOData> = Object.freeze({
  [E_AlarmType.Vibration]: {
    intervalCount: 3,
    intervalTime: toMinute(30, E_TimeUnit.Minute),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.Steepness]: {
    intervalCount: 3,
    intervalTime: toMinute(1, E_TimeUnit.Hour),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.Crest]: {
    intervalCount: 3,
    intervalTime: toMinute(1, E_TimeUnit.Hour),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.Temperature]: {
    intervalCount: 3,
    intervalTime: toMinute(30, E_TimeUnit.Minute),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.Voltage]: {
    intervalCount: 3,
    intervalTime: toMinute(24, E_TimeUnit.Hour),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.Envelope]: {
    intervalCount: 3,
    intervalTime: toMinute(24, E_TimeUnit.Hour),
    effectiveIntervalCount: 3,
  },
  [E_AlarmType.HighFrequencyAcceleration]: {
    intervalCount: 3,
    intervalTime: toMinute(24, E_TimeUnit.Hour),
    effectiveIntervalCount: 3,
  },
})

export function hasAlarmTypeConfig(alarmType: E_AlarmType) {
    return _.has(DefualtAlarmConfigs, alarmType)
}

export interface IAlarmConfig {
  intervalCount: number
  intervalTime: number
}
