/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import VueRouter from "vue-router";
import { Box } from "./utils/box";
import { Store } from "vuex";

export const gStore = new Box<Store<any>>()
export const gRouter = new Box<VueRouter>()
