/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Prop, Ref } from 'vue-property-decorator'
import { IWaveSpectrumQuery } from '../../types'
import { FrequencyTimerFilterImpl } from '../FrequencyTimerFilter/FrequencyTimerFilterImpl'
import { ChartPanelImpl, IChartPanelOptions } from '../ChartPanel/ChartPanelImpl'
import { ISamplingTimeData } from '@/api/moudules/wavefrom.types'

import FrequencyTimerFilter from '../FrequencyTimerFilter/FrequencyTimerFilter.vue'
import ChartPanel from '../ChartPanel/ChartPanel.vue'
import RadioOptions from "@/components/RadioOptions/index.vue";

@Component({
    name: 'MultipleChart',
    components: {
        RadioOptions,
        ChartPanel,
        FrequencyTimerFilter,
    }
})
export class MultipleChartImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    @Prop({
        type: Object,
    }) chartPanelOptions: IChartPanelOptions

    @Ref()
    frequencyTimerFilterRef: FrequencyTimerFilterImpl

    onFrequencyTimerChange(value: ISamplingTimeData, checked: boolean) {
        if (checked) {
            this.selectedSamplings = [...this.selectedSamplings, value]
        } else {
            this.selectedSamplings = _.filter(this.selectedSamplings, s => s.waveId !== value.waveId)
        }
        this.$nextTick(() => {
            _.each(this.chartPanelsRef, chartPanel => {
                chartPanel.chartRef.resizeChart()
            })
        })
    }

    onSearch() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onQuery()
        }
    }

    onReset() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onReset()
        }
        this.selectedSamplings = []
    }

    selectedSamplings: ISamplingTimeData[] = []

    get HasData() {
        return !_.isEmpty(this.selectedSamplings)
    }

    get HasMoreThen3() {
        return _.size(this.selectedSamplings) > 3
    }

    get SelectedWaveIds() {
        return _.map(this.selectedSamplings, s => s.waveId)
    }

    @Ref()
    chartPanelsRef: ChartPanelImpl[]
}
