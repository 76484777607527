/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { Vue, Component, Ref, Prop, } from 'vue-property-decorator'

import RadioOptions from "@/components/RadioOptions/index.vue";
import { E_ChartType } from "@/utils/chart";
import { ISamplingTimeData } from '@/api/moudules/wavefrom.types';
import { IWaveSpectrumQuery } from '../types';
import {
    Collapse,
    CollapseItem,
    CollapseArrow
} from '@/components/Collapse/index'
import * as echarts from 'echarts'
import { spectrum, trend } from '@/charts/waveChartOption';
import { getAxialColor } from "@/utils/color"
import { E_Eigenvalue } from '@/types';
import { formatTitle2, isCheckQueryFailedAndReport, isCheckWaveIdFailedAndReport, WavePageOptions } from '@/view_helper/wave';
import FrequencyTimerFilter from './FrequencyTimerFilter/FrequencyTimerFilter.vue'
import { FrequencyTimerFilterImpl } from './FrequencyTimerFilter/FrequencyTimerFilterImpl';
import { EChartImpl } from '@/components/EChart/EChartImpl';
import { formatDateTime } from '@/utils/datetime';
import { IGetHealthPointData } from '@/api/moudules/statistics.types';
import LeftRight from '@/components/LeftRight/LeftRight.vue';
import { contentFileName, download } from '@/utils/download';


function makeQueryForm() {
    return {
        pageNum: 1,
        pageSize: 15,
    }
}

function makeWaveQueryForm() {
    return {
        pageNum: 1,
        pageSize: WavePageOptions[0]
    }
}

@Component({
    name: 'TrendWaveForm',
    components: {
        RadioOptions,
        Collapse,
        CollapseItem,
        CollapseArrow,
        FrequencyTimerFilter,
        LeftRight,
    },
})
export class TrendWaveFormImpl extends Vue {
    @Prop({
        type: Object,
    }) query: IWaveSpectrumQuery

    @Ref()
    frequencyTimerFilterRef: FrequencyTimerFilterImpl


    onFrequencyTimerSelect(value: ISamplingTimeData) {
        this.samplingTime = value
        this.waveQueryForm = makeWaveQueryForm()
        this.updateWaveChart()
        this.updateSpectrogramChart()
        this.updateTrendChart()
    }

    queryForm = makeQueryForm()

    toChartOptions(chartTypes: E_ChartType[]) {
        return _.map(chartTypes, chartType => {
            return {
                label: this.$enumTrans.ChartType(chartType),
                value: chartType,
            }
        })
    }

    // 图表选项
    get chart1Options() {
        let res: any[] = [
            E_ChartType.AccelerationTimeDomain,
            E_ChartType.VelocityDomain,
        ]

        res = [
            ...res,
            E_ChartType.DisplacementTimeDomain,
        ]
        return this.toChartOptions(res)
    }

    get chart2Options() {
        let res: any[] = [
            E_ChartType.AccelerationFrequencyDomain,
            E_ChartType.VelocityFrequencyDomain,
        ]

        res = [
            ...res,
            E_ChartType.DisplacementSpectrum,
            E_ChartType.EnvelopeSpectrum,
        ]
        return this.toChartOptions(res)
    }
    get chart3Options() {
        let res: any[] = [
            E_ChartType.AcceleratedSpeed,
            E_ChartType.Vibration,
            E_ChartType.Displacement,
            E_ChartType.Temperature,
            E_ChartType.TemperatureRise,
            E_ChartType.Peakedness,
            E_ChartType.CrestFactor,
            E_ChartType.EnvelopeDemodulationValue,
            // E_ChartType.Speed,
        ]
        return this.toChartOptions(res)
    }
    // 当前类型
    chartActive1: any = E_ChartType.AccelerationTimeDomain
    chartActive2: any = E_ChartType.AccelerationFrequencyDomain
    chartActive3: any = E_ChartType.AcceleratedSpeed


    samplingTime: ISamplingTimeData = null

    get WaveId() {
        if (this.samplingTime) {
            return this.samplingTime.waveId
        }
        return null
    }

    get WaveDateTime() {
        if (this.samplingTime) {
            return this.samplingTime.dateTime
        }
        return null
    }

    WavePageOptions = WavePageOptions

    waveStartPoint = 0
    waveEndPoint = 0
    waveTotalPoint = 0

    waveQueryForm = makeWaveQueryForm()

    onWavePageSizeChange(_value) {
        this.waveQueryForm.pageNum = 1
        this.updateWaveChart()
    }

    get EnabledWaveLeft() {
        return this.waveQueryForm.pageNum > 1
    }

    get EnabledWaveRight() {
        const {
            waveTotalPoint
        } = this

        const {
            pageNum,
            pageSize,
        } = this.waveQueryForm
        return pageNum * pageSize < waveTotalPoint
    }

    onPreWavePage() {
        this.waveQueryForm.pageNum -= 1
        this.updateWaveChart()
    }
    onNextWavePage() {
        this.waveQueryForm.pageNum += 1
        this.updateWaveChart()
    }

    requestWaveDetail(chartType) {
        const {
            pageNum,
            pageSize,
        } = this.waveQueryForm
        const params = {
            pageNum,
            pageSize,
            mappingId: this.query.mappingId,
            chartType,
            waveId: this.WaveId
        }
        return this.$api.wave.waveDetail(params)
    }

    // #region   波形图 

    // 波形图导出
    get ShowExport() {
        return !_.isNil(this.WaveId) && !_.isNil(this.query.mappingId)
    }
    onExportClick() {
        this.$api.excel.excelWareExport({
            waveId: this.WaveId,
            mappingId: this.query.mappingId,
        }).then(res => {
            const filenameHeader = (res as any).headers['content-disposition']
            const filename = contentFileName(filenameHeader)
            download(res.data, filename)
        }).catch(err => {
            this.$message({
                type: 'error',
                message: this.$t('errors.exportFailed') as string
            })
        })
    }


    @Ref()
    waveChartRef: EChartImpl

    pointData: IGetHealthPointData = {} as any

    isPreCheckFailed() {
        if (isCheckQueryFailedAndReport(this, this.query)) {
            return true
        }
        if (isCheckWaveIdFailedAndReport(this, this.WaveId)) {
            return true
        }
    }

    // 波形图切换
    handleChangeChart1(value) {
        this.chartActive1 = value
        this.updateWaveChart()
    }

    wavePointNumbers = 0

    async requestFnWaveChart(chart: echarts.ECharts) {
        if (this.isPreCheckFailed()) {
            return
        }
        this.wavePointNumbers = 0
        const res = await this.requestWaveDetail(this.chartActive1)
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            })
            return;
        }
        this.$emit('updateTitle', formatTitle2(data.title))
        const {
            offset,
            end,
        } = data
        this.waveStartPoint = offset + 1
        this.waveEndPoint = end + 1
        this.pointData = (data as any).headInfo || {}
        this.waveTotalPoint = this.pointData.wavePoint
        let option = spectrum(this, this.chartActive1 as any, data.wave, data.interval) as any
        option.color = [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')];
        option.xAxis.axisLabel = {
            interval: (index, value) => {
                if (index % 200 == 0) {
                    return value;
                }
            },
            formatter: (value, index) => {
                const n = value.substr(0, value.length - 2);
                const u = value.substr(value.length - 2, value.length);
                return parseInt(n) + u;
            }
        }
        chart.setOption(option, true, true);
    }

    updateWaveChart() {
        this.waveChartRef.request()
    }
    //#endregion


    //#region 频谱图   

    @Ref()
    spectrogramChartRef: EChartImpl

    // 频谱图切换
    handleChangeChart2(value) {
        this.chartActive2 = value
        this.updateSpectrogramChart()
    }

    async requestFnSpectrogramChart(chart: echarts.ECharts) {
        if (this.isPreCheckFailed()) {
            return
        }
        const res = await this.requestWaveDetail(this.chartActive2)
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            });
            return;
        }
        let option = spectrum(this, this.chartActive2, data.wave, data.interval) as any
        option.color = [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')]
        if (this.chartActive2 == E_Eigenvalue.EnvelopeSpectrum) {
            // 包络
            // option.xAxis.axisLabel = {
            //     interval: (index, value) => {
            //         if (index % 200 == 0) {
            //             return value;
            //         }
            //     },
            //     formatter: (value, index) => {
            //         const n = value.substr(0, value.length - 2);
            //         const u = value.substr(value.length - 2, value.length);
            //         return parseInt(n) + u;
            //     }
            // };
        } else {
            // option.xAxis.axisLabel = {
            //     interval: (index, value) => {
            //         for (let i = 0; i <= 10; i++) {
            //             if (index == 205 * i) {
            //                 return value;
            //             }
            //         }
            //     },
            //     formatter: (value, index) => {
            //         const n = value.substr(0, value.length - 2);
            //         const u = value.substr(value.length - 2, value.length);
            //         return parseInt(n) + u;
            //     }
            // };
        }
        chart.setOption(option, true, true);

    }

    updateSpectrogramChart() {
        this.spectrogramChartRef.request()
    }

    //#endregion

    // #region 趋势图
    @Ref()
    trendChartRef: EChartImpl

    // 趋势图切换
    handleChangeChart3(value) {
        this.chartActive3 = value
        this.updateTrendChart()
    }

    async requestFnTrendChart(chart: echarts.ECharts) {
        if (this.isPreCheckFailed()) {
            return
        }
        const res = await this.$api.statistics.getDataByTimeRange({
            mappingId: String(this.query.mappingId),
            chartType: this.chartActive3,
            time: formatDateTime(this.WaveDateTime)
        })
        const { code, data } = res;
        if (code != 200 || null == data) {
            this.$message({
                message: this.$t('errors.noData') as string,
                type: 'success'
            });
            return;
        }
        let option = trend(this, this.chartActive3, false, data) as any
        option.color = [getAxialColor('x'), getAxialColor('y'), getAxialColor('z')];
        chart.setOption(option, true, true);
    }

    updateTrendChart() {
        this.trendChartRef.request()
    }
    //#endregion

    // 搜索
    onSearch() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onQuery()
        }
    }

    onReset() {
        if (this.frequencyTimerFilterRef) {
            this.frequencyTimerFilterRef.onReset()
        }
        _.each([this.waveChartRef, this.trendChartRef, this.spectrogramChartRef], chartRef => {
            chartRef.reset()
        })
    }
}
