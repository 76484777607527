/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import axios from "../axios";
import {
    ICurrentUserInfo,
    IMenuCreateSaveParams,
    IMenuListData,
    IRoleAddParams,
    IRoleEditParams,
    IRoleListData,
    IRoleListParams,
    IUserAddParams,
    IUserCurrentSysMenusData,
    IUserRolesFindListParams,
    IUserListData,
    IUserListParams,
    IUserMotifyPasswordParams,
    IUserResetPasswordParams,
    IUserSwitchOpenStatusParams,
    IUserUpdateRolesParams,
    IUserRolesFindListData
} from "./system.types";

/**
 * $t('enum.E_Sex.Man')
 * $t('enum.E_Sex.Woman')
 * $t('enum.E_Sex.Unknown')
 */
export enum E_Sex {
    /**
     * 男
     */
    Man = '0',
    /**
     * 女
     */
    Woman = '1',
    /**
     * 未知
     */
    Unknown = '2',
}

export const E_Sex__Invert = _.invert(E_Sex)


//#region 用户
/**
 * 分页查询
 */
export function userList(params: IUserListParams) {
    return axios<IUserListData>({
        method: 'post',
        url: '/system/user/findPage',
        data: params,
    })
}

/**
 * 新增用户
 */
export function userAdd(params) {
    return axios({
        method: 'post',
        url: '/system/user/add',
        data: params
    })
}

/**
 * 删除用户
 */
export function userDel(params: { userIds: string }) {
    return axios({
        method: 'delete',
        url: `/system/user/remove/${params.userIds}`
    })
}

/**
 * 修改用户
 */
export function userEdit(params) {
    return axios({
        method: 'put',
        url: '/system/user/edit',
        data: params,
    })
}

/**
 * 根据用户ID查询用户信息
 */
export function userGet(params: { userId: number }) {
    return axios({
        method: 'get',
        url: `/system/user/${params.userId}`
    })
}

/**
 * 用户状态开启关闭 
 */
export function userSwitchOpenStatus(params: IUserSwitchOpenStatusParams) {
    return axios({
        method: 'get',
        url: '/system/user/openStatus',
        params,
    })
}

/**
 * 重置用户密码
 */
export function userResetPassword(params: IUserResetPasswordParams) {
    return axios({
        method: 'post',
        url: '/system/user/resetPassword',
        data: params,
    })
}


/**
 * 获取当前登录用户信息
 */
export function userCurrentInfo() {
    return axios<ICurrentUserInfo>({
        method: 'get',
        url: '/system/user/getInfo',
    })
}
/**
 * 获取当前登录用户菜单信息
 */
export function userCurrentSysMenus() {
    return axios<IUserCurrentSysMenusData>({
        method: 'get',
        url: '/system/user/getSysMenus'
    })
}

/**
 * 修改密码
 */
export function userMotifyPassword(params: IUserMotifyPasswordParams) {
    return axios({
        method: 'post',
        url: '/system/user/modifyPassword',
        data: params,
    })
}

/**
 * 分配角色
 */
export function userFindRoleList(params: IUserRolesFindListParams) {
    return axios<IUserRolesFindListData>({
        method: 'post',
        url: '/system/user/findList',
        data: params,
    })
}

/**
 * 修改用户角色
 */
export function userUpdateRoles(params: IUserUpdateRolesParams) {
    return axios({
        method: 'post',
        url: '/system/user/updateRoles',
        data: params,
    })
}

/**
 * 获取微信通知地址 
 */
export function userGetWeixinNoticeUrl(){
    return axios<string>({
        method: 'get',
        url: '/system/user/getWeixinNoticeUrl'
    })
}

//#endregion

//#region 角色

/**
 * 新增角色
 */
export function roleAdd(params: IRoleAddParams) {
    return axios({
        method: 'post',
        url: '/system/role/add',
        data: params,
    })
}
/**
 * 修改角色
 */
export function roleEdit(params: IRoleEditParams) {
    return axios({
        method: 'put',
        url: '/system/role/edit',
        data: params,
    })
}
/**
 * 删除角色
 */
export function roleDel(params: { roleIds: string }) {
    return axios({
        method: 'delete',
        url: `/system/role/remove/${params.roleIds}`
    })
}
/**
 * 分页查询角色列表
 */
export function roleList(params: IRoleListParams) {
    return axios<IRoleListData>({
        method: 'post',
        url: '/system/role/findPage',
        data: params,
    })
}

/**
 * 根据角色ID查询角色信息
 */
export function roleGet(params: { roleId: number }) {
    return axios({
        method: 'get',
        url: `/system/role/${params.roleId}`
    })
}

//#endregion

//#region 菜单
export function menuList(params) {
    return axios<IMenuListData>({
        method: 'get',
        url: '/system/menu/findAll',
        params,
    })
}

export function menuCreateSave(params: IMenuCreateSaveParams) {
    return axios({
        method: 'post',
        url: '/system/menu/save',
        data: params,
    })
}
export function menuDel(params: { id: number }) {
    return axios({
        method: 'get',
        url: '/system/menu/del',
        params,
    })
}
//#endregion
