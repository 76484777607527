import { IPageData, IPageParams } from "../types"

export enum E_CollectCardType {
    NET2412 = 1
}

/**
 * 技术模式
 * $t('enum.E_CollectCardCoutingMode.Add')
 * $t('enum.E_CollectCardCoutingMode.Sub')
 */
export enum E_CollectCardCoutingMode {
    /**
     * 加法
     */
    Add = 0,

    /**
     * 减法
     */
    Sub = 1,
}

/**
 * 方向
 * $t('enum.E_CollectCardDirection.Single')
 * $t('enum.E_CollectCardDirection.Double')
 */
export enum E_CollectCardDirection {
    /**
     * 单边沿
     */
    Single = 1,
    /**
     * 双边沿
     */
    Double = 2,
}

/**
 * $t('enum.E_SelectChannel.Yes')
 * $t('enum.E_SelectChannel.No')
 */
export enum E_SelectChannel {
    /**
     * 选中
     */
    Yes = 1,
    /**
     * 未选择
     */
    No = 2,
}

/**
 * 采集模式
 * $t('enum.E_CollectCardCollMode.Soft')
 * $t('enum.E_CollectCardCollMode.External')
 */
export enum E_CollectCardCollMode {
    /**
     * 软触发
     */
    Soft = 0,
    /**
     * 外触发
     */
    External = 1,
}

/**
 * 触发方向
 * $t('enum.E_CollectCardTriggerDirection.RisingEdge')
 * $t('enum.E_CollectCardTriggerDirection.FallingEdge')
 * $t('enum.E_CollectCardTriggerDirection.BothEdge')
 */
export enum E_CollectCardTriggerDirection {
    /**
     * 上升沿触发
     */
    RisingEdge = 0,
    /**
     * 下降沿触发
     */
    FallingEdge = 1,
    /**
     * 双边沿触发
     */
    BothEdge = 2,
}

/**
 * 恒流源选择
 * $t('enum.E_ConstantCurrentSource.OpenCurrentAC')
 * $t('enum.E_ConstantCurrentSource.CloseCurrentAC')
 * $t('enum.E_ConstantCurrentSource.CloseCurrentDC')
 */
export enum E_ConstantCurrentSource {
    /**
     * 开启恒流源/AC耦合
     */
    OpenCurrentAC = 0,
    /**
     * 关闭恒流源/AC耦合
     */
    CloseCurrentAC = 1,
    /**
     * 关闭恒流源/DC耦合
     */
    CloseCurrentDC = 2,
}

/**
 * 通道类型
 * $t('enum.E_ChannelType.Temperature')
 * $t('enum.E_ChannelType.RotateSpeed')
 * $t('enum.E_ChannelType.Vibration')
 */
export enum E_ChannelType {
    /**
     * 温度
     */
    Temperature = 1,
    /**
     * 转速
     */
    RotateSpeed = 2,
    /**
     * 振动
     */
    Vibration = 3,
}

export interface ICollectCardAddParams {
    /**
     * 
     */
    companyId: number
    /**
     * 
     */
    areaId: number
    /**
     * 采集卡ip
     */
    cardIp: string
    /**
     * 采集卡名称
     */
    cardName: number
    /**
     * 采集卡类型
     */
    cardType: E_CollectCardType
    /**
     * 通道数量
     */
    channelNum: number
    /**
     * 服务器ip
     */
    serverIp: string
    /**
     * 服务器端口
     */
    serverPort: number
}


export interface ICollectCardAddCollectParams {
    /**
     * AD采集频率
     */
    collFrequency: number
    /**
     * 采集模式
     */
    collMode: E_CollectCardCollMode
    /**
     * 采集卡表主键id
     */
    collectCardId: number
    /**
     * 触发方向
     */
    triggerDirection: E_CollectCardTriggerDirection
}

export interface ISpeedBase {
    /**
     * 计数器初值
     */
    counterOriginalVal: number
    /**
     * 技术模式
     */
    countingMode: E_CollectCardCoutingMode
    /**
     * 方向
     */
    direction: E_CollectCardTriggerDirection
    /**
     * 测频周期(1-255)
     */
    freqMeasure: number
    /**
     * 齿数
     */
    gearNumber: number
    /**
     * 是否选中
     */
    selectChannel: E_SelectChannel
}


export interface ISpeedAddVO extends ISpeedBase {
    /**
     * 通道号
     */
    channelId: number

}

export interface ICollectCardAddSpeedParams {
    collectCardId: number
    speedAddVOList: ISpeedAddVO[]
}


export interface ICollectCardDetailParams {
    id: number
}

export interface ICollectCardDetailData extends ICollectCardAddParams {
    id: number
    isDel: number
    updateTime: string

    /**
     * AD采集频率
     */
    collFrequency: number
    /**
     * 采集模式
     */
    collMode: E_CollectCardCollMode
    /**
     * 触发方向
     */
    triggerDirection: E_CollectCardTriggerDirection
}

export interface ICollectCardEditParams extends ICollectCardAddParams {
    id: number
}

export interface ICollectCardGetChannelDetailListParams {
    collectCardId: number
}

export interface IChannelDetail {
    id: number
    /**
     * 通道类型
     */
    channelType: E_ChannelType
    /**
     * 通道名称
     */
    channelName: string
}

export type ICollectCardGetChannelDetailListData = IChannelDetail[]

export interface ICollectCardGetChannelVerListParams {
    collectCardId: number
}

export interface ICollChannelVerDetailVO {
    accSensitivity: number
    channelId: number
    channelName: string
    constantCurrentSource: E_ConstantCurrentSource
    id: number
    selectChannel: E_SelectChannel
    vvalue: number
}
export type ICollectCardGetChannelVerListData = ICollChannelVerDetailVO[]


export interface ICollectCardGetChannelListParams {
    collectCardId: number
}

export interface ICollChannelBase {
    /**
     * B1值
     */
    b1Value: number
    /**
     * 通道id
     */
    channelId: number
    /**
     * 恒流源选择
     */
    constantCurrentSource: E_ConstantCurrentSource
    /**
     * 采集卡表主键id
     */
    id: number
    /**
     * 是否选中
     */
    selectChannel: E_SelectChannel
    /**
     * 温度电压关系
     */
    temVoltage: number
    /**
     * 
     */
    vvalue: number
}

export interface ICollChannelDetailVO extends ICollChannelBase {
    /**
     * 通道名
     */
    channelName: string
}

export type ICollectCardGetChannelListData = ICollChannelDetailVO[]

export interface ICollectCardGetCollListParams {
    areaId: number
}

export interface ICollDetail {
    id: number
    /**
     * 卡名
     */
    cardName: string
    /**
     * AD采集频率(KHZ)
     */
    collFrequency: number
}
export type ICollectCardGetCollListData = ICollDetail[]


export interface ICollectCardGetSpeedDetailParams {
    collectCardId: number
}

export interface ISpeedEditVO extends ISpeedAddVO {
    id: number
}

export type ICollectCardGetSpeedDetailData = ISpeedEditVO[]


export type ICollectCardQueryListPageParams = IPageParams & Partial<{
    areaId: string
    /**
     * 采集卡名称 模糊搜索
     */
    cardName: string
    /**
     * 必选
     */
    companyId: string
}>

export interface ICollectCardQueryListPageItem {
    id: number
    /**
     * 
     */
    companyId: number
    companyName: string
    /**
     * 
     */
    areaId: number
    areaName: string
    /**
     * 采集卡ip
     */
    cardIp: string
    /**
     * 采集卡名称
     */
    cardName: number
    /**
     * 采集卡类型
     */
    cardType: E_CollectCardType
    /**
     * 通道数量
     */
    channelNum: number
    /**
     * 服务器ip
     */
    serverIp: string
    /**
     * 服务器端口
     */
    serverPort: number
    createTime: string
}

export type ICollectCardQueryListPageData = IPageData<ICollectCardQueryListPageItem>


export interface ICollectCardUpdateChannelParams {
    collectCardId: number
    collChannelUpdateVOS: ICollChannelBase[]
}

export interface ICollVibrationUpdateVO {
    /**
     * 加速度灵敏度
     */
    accSensitivity: number
    /**
     * 通道id
     */
    channelId: number
    /**
     * 恒流源选择
     */
    constantCurrentSource: E_ConstantCurrentSource
    /**
     * 采集卡表主键id
     */
    id: number
    /**
     * 是否选中
     */
    selectChannel: E_SelectChannel
    vvalue: number
}

export interface ICollectCardUpdateCollVibrationParams {
    /**
     * 采集卡表主键id
     */
    collectCardId: number
    /**
     * 振动配置
     */
    collVibrationUpdateVOS: ICollVibrationUpdateVO[]
}


export interface ISpeedUpdateVO extends ISpeedBase {
    id: number
}


export interface ICollectCardUpdateSpeedParams {
    /**
     * 采集卡表主键id
     */
    collectCardId: number
    /**
     * 转速配置
     */
    speedUpdateVOList: ISpeedUpdateVO[]
}


export interface ICollectCardSelectCollVibrationParams {
    /**
     * 采集卡通道id
     */
    cardChannelId: number[]
    /**
     * 采集卡表主键id
     */
    collectCardId: number
    /**
     * 过去多少分钟
     */
    minute: number
}

export interface ICollectCardSelectCollVibrationItem {
    cardChannelId: number
    collVibrationResultVOS: {
        createTime: string
        vz: number
    }[]
}

export type ICollectCardSelectCollVibrationData =
    ICollectCardSelectCollVibrationItem[]


export interface ICollectCardSelectCollVibrationChannelParams {
    /**
     * 
     */
    collectCardId: number
}

export interface ICollectCardSelectCollVibrationChannelItem {
    /**
     * 通道id
     */
    channelId: number
    /**
     * 采集卡表主键id
     */
    collectCardId: number
    /**
     * 
     */
    id: number
    /**
     * 
     */
    vz: number

    channelName: string
}

export type ICollectCardSelectCollVibrationChannelData = ICollectCardSelectCollVibrationChannelItem[]
