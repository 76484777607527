/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IOptionNumberItem, IPageData, IPageParams } from "../types"
import { E_CollectMode } from "./sensor.types"

/**
 * 网关型号
 * $t('enum.E_GateWayModel.VB22H')
 * $t('enum.E_GateWayModel.VB24H')
 */
export enum E_GateWayModel {
    VB22H = 1,
    VB24H = 2,
}

/**
 * $t('enum.E_DHCPModel.DHCP')
 * $t('enum.E_DHCPModel.Static')
 */
export enum E_DHCPModel {
    DHCP = 1,
    Static = 0,
}

/**
 * 数据上云状态
 * $t('enum.E_DataStatus.NotUpload')
 * $t('enum.E_DataStatus.Upload')
 */
export enum E_DataStatus {
    /**
     * 不上传
     */
    NotUpload = 0,
    /**
     * 上传
     */
    Upload = 1,
}

/**
 * $t('enum.E_GatewayStatus.Offline')
 * $t('enum.E_GatewayStatus.Connecting')
 */
export enum E_GatewayStatus {
    /**
     * 未连接
     */
    Offline = 0,
    /**
     * 已连接
     */
    Connecting = 1,
}

/**
 * 网关类型
 * $t('enum.E_GatewayType.Bluetooth')
 * $t('enum.E_GatewayType.LoRa')
 * $t('enum.E_GatewayType.ZigBee')
 * $t('enum.E_GatewayType.RS485')
 * $t('enum.E_GatewayType.RVT50')
 */
export enum E_GatewayType {
    /**
     * 蓝牙网关
     */
    Bluetooth = 19,
    /**
     * LoRa网关
     */
    LoRa = 20,
    /**
     * ZigBee网关
     */
    ZigBee = 21,
    /**
     * RS485
     */
    RS485 = 22,
    /**
     * RVT50
     */
    RVT50 = 28,
}

// export enum E_ConnectType_Bluetooth {
//     MacAddress = '输入网关MAC地址',
// }

/**
 * $t('enum.E_ConnectType_LoRa.EncryptionKey')
 * $t('enum.E_ConnectType_LoRa.FrequencyPair')
 * $t('enum.E_ConnectType_LoRa.BindSensors')
 */
export enum E_ConnectType_LoRa {
    /**
     * 输入网关密钥
     */
    EncryptionKey = 23,
    /**
     * 输入发射频段、接收频段
     */
    FrequencyPair = 24,
    /**
     * 绑定的传感器种类（多选）
     */
    BindSensors = 25,
}

// export enum E_ConnectType_ZigBee {
//     NetworkChannel = '输入网关网络号/通道号',
// }

// export enum E_ConnectType_RS485 {
//     HeartbeatPacket = '输入网关心跳报文',
// }

/**
 * 绑定传感器类型
 * $t('enum.E_BindSensorType.VibrationTypeWiredPowerSupply')
 * $t('enum.E_BindSensorType.NonVibrationMixing')
 */
export enum E_BindSensorType {
    /**
     * 振动类有线供电
     */
    VibrationTypeWiredPowerSupply = 27,
    /**
     * 非振动类混合
     */
    NonVibrationMixing = 26,
}

/**
 * 波形采集模式
 */
export enum E_WaveCollectMode {
    Value_3 = 3,
    Value_6 = 6,
    Value_12 = 12,
    Value_24 = 24,
}


export interface IGatewayListPageParams extends IPageParams {
    /**
     * 网关名称
     */
    name?: string
    areaId?: number
    companyId?: number
}

export interface IGateWayListItem {
    /**
     * 网关类型
     */
    gatewayTypeId: number
    areaid?: number;
    /**
     * 区域名称
     */
    areaName?: string;
    companyid?: number;
    /**
     * 公司名称
     */
    companyName?: string;
    createBy?: null;
    createTime?: null;
    delFlag?: number;
    /**
     * ID号
     */
    devid?: string;
    /**
     * IP号
     */
    devip?: number;
    id?: number;
    /**
     * 安装位置
     */
    location?: string;
    /**
     * 网关mac地址
     */
    mac?: string;
    /**
     * 网关名称
     */
    name?: string;
    /**
     * 0未连接 1已连接
     */
    status?: boolean;
    /**
     * 网关类型Id
     */
    type?: string;
    /**
     * 网关类型
     */
    typeName?: string;
    updateBy?: null;
    updateTime?: null;

    /**
     * 网关已添加传感器数量
     */
    sensorNum?: number

    /**
     * 波形采集模式
     */
    collectMode?: number

    /**
     * 数据上云状态
     */
    dataStatus?: E_DataStatus
}

export type IGatewayListPageData = IPageData<IGateWayListItem>

export interface IGatewayType {
    gatewayModel: E_GateWayModel

    dhcp: E_DHCPModel

    /**
     * 静态IP
     */
    staticIp: string
    /**
     * 子网掩码
     */
    subnetMask: string
    /**
     * 网关IP
     */
    gatewayIp: string

    /**
     * 网关类型ID
     */
    gatewayType: E_GatewayType
    /**
     * 接收频段
     */
    acceptTransmission: string;
    /**
     * 通道号
     */
    channelNumber: string;
    /**
     * 连接类型
     */
    connectType: string;
    /**
     * 网关心跳报文
     */
    heartBeat: string;
    id?: number;
    /**
     * 网关网络号
     */
    networkNumber: string;
    /**
     * 密钥
     */
    secret: string;
    /**
     * 绑定传感器种类ID
     */
    sensorKind: number;
    /**
     * 传感器类型 逗号隔开
     */
    sensorType: string;
    /**
     * 发射频段
     */
    transmission: string;
    /**
     * 网关MAC地址
     */
    blueMac: string

    //#region RVT50
    /**
     * 第一通道
     */
    firstChannel: number
    /**
     * 第二通道
     */
    secondChannel: number
    /**
    * 第三通道
    */
    thirdChannel: number
    /**
     * 第四通道
     */
    fourthChannel: number
    /**
     * 转速
     */
    rotateSpeed: number
    /**
     * 转速比值
     */
    speedRatio: number
    //#endregion

    /**
     * 无线通道
     */
    wirelessChannel: number
    /**
     * 频段
     */
    wirelessFrequency: number
}

export interface IGatewayCreateParams {
    areaId: number;
    companyId: number;
    /**
     * ID号
     */
    devID: string;
    /**
     * ip
     */
    devIP: string;

    /**
     * 端口号
     */
    devPort: string

    gatewayType: IGatewayType;

    

    /**
     * 安装位置
     */
    location?: string;
    /**
     * mac
     */
    mac: string;
    /**
     * 网关名称
     */
    name: string;
    /**
     * 0未连接 1已连接
     */
    // status: E_GatewayStatus
    collectMode: E_WaveCollectMode
}


export interface IGatewayItem extends IGatewayCreateParams {
    id: number
    areaName: string
    companyName: string
    collectMode: E_WaveCollectMode
    gatewayType: IGatewayType & { id: number }
}

export interface IGatewayEditParams extends IGatewayCreateParams {
    id: number
    gatewayType: IGatewayType & { id: number }
}

export interface IGatewaySelectListData {
    /**
     * 绑定传感器种类
     */
    sensorKind: IOptionNumberItem[]
    /**
     * 网关类型
     */
    gatewayType: IOptionNumberItem[]
    /**
     * 连接类型
     */
    connectType: IOptionNumberItem[]
}

export interface IGatewayListItem extends IOptionNumberItem {
    gateWayTypeId: number
    /**
     * 暂时无用
     */
    type: number
}

export type IGatewayListData = IGatewayListItem[]
