/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import screenfull from "screenfull"
import { ECharts } from "echarts"
import * as echarts from 'echarts'

export function safeResizeChart(chart: ECharts) {
    if (chart) {
        chart.resize()
    }
}

function showFullScreen(chart: ECharts, show: boolean) {
    chart.setOption({
        toolbox: {
            feature: {
                myFullScreen: {
                    show,
                },
                myExitFullScreen: {
                    show: !show,
                }
            }
        }
    })
    chart.resize()
}

function onFullScreenClick(model, cls, type, ev: MouseEvent) {
    const dom = cls.getDom()
    const chart = echarts.getInstanceByDom(dom)
    if (type === 'myFullScreen') {
        const handler = () => {
            if (!screenfull.isFullscreen) {
                showFullScreen(chart, true)
                screenfull.off('change', handler)
            }
        }
        screenfull.on('change', handler)
        screenfull.request(dom).then(res => {
            showFullScreen(chart, false)
        })
    } else if (type === 'myExitFullScreen') {
        screenfull.exit().then(res => {
            showFullScreen(chart, true)
        })
    }
}

export const FullScreenOptions = {
    'myFullScreen': {
        show: true,
        title: '全屏查看',
        icon: 'M285.866667 810.666667H384v42.666666H213.333333v-170.666666h42.666667v98.133333l128-128 29.866667 29.866667-128 128z m494.933333 0l-128-128 29.866667-29.866667 128 128V682.666667h42.666666v170.666666h-170.666666v-42.666666h98.133333zM285.866667 256l128 128-29.866667 29.866667-128-128V384H213.333333V213.333333h170.666667v42.666667H285.866667z m494.933333 0H682.666667V213.333333h170.666666v170.666667h-42.666666V285.866667l-128 128-29.866667-29.866667 128-128z',
        onclick: onFullScreenClick,
    },
    'myExitFullScreen': {
        show: false,
        title: '退出全屏',
        icon: 'M354.133333 682.666667H256v-42.666667h170.666667v170.666667H384v-98.133334L243.2 853.333333l-29.866667-29.866666L354.133333 682.666667z m358.4 0l140.8 140.8-29.866666 29.866666-140.8-140.8V810.666667h-42.666667v-170.666667h170.666667v42.666667h-98.133334zM354.133333 384L213.333333 243.2l29.866667-29.866667L384 354.133333V256h42.666667v170.666667H256V384h98.133333z m358.4 0H810.666667v42.666667h-170.666667V256h42.666667v98.133333L823.466667 213.333333l29.866666 29.866667L712.533333 384z',
        onclick: onFullScreenClick,
    }
}

export const Toolbox =
{
    right: "24rem",
    iconStyle: {
        borderColor: "#fff",
    },
    feature: {
        ...FullScreenOptions,
        dataZoom: {
            title: {
                zoom: "区域缩放",
                back: "缩放还原",
            },
            yAxisIndex: "none",
            icon: {
                zoom: "path://M847.890496 961.684329H174.592253c-61.918295 0-112.168207-50.177662-112.168207-112.168207V176.217879c0-61.990545 50.249912-112.168207 112.168207-112.168207H847.854371c61.990545 0 112.276582 50.177662 112.276582 112.168207v673.262118c0 62.02667-50.249912 112.204332-112.240457 112.204332z m56.138291-785.46645c0-30.959148-25.106893-56.138291-56.138291-56.138291H174.592253c-30.959148 0-56.029916 25.179143-56.029916 56.138291v673.262118c0 30.959148 25.106893 56.138291 56.029916 56.138291H847.854371c31.031398 0 56.138291-25.179143 56.138291-56.138291V176.217879h0.036125z m-84.171312 308.616101c-15.461511 0-28.033021-12.643759-28.03302-28.10527l0.903126-184.418261-190.487265 190.37889-39.665279-39.593029 190.74014-190.74014h-185.935511c-15.461511 0-28.033021-12.643759-28.033021-28.10527 0-15.497636 12.535384-28.033021 28.033021-28.033021h251.683059c7.839131 0 14.955761 3.215127 20.013265 8.453256 5.418754 4.551753 8.814506 11.307133 8.814506 19.579765v252.47781c0 15.461511-12.571509 28.105271-28.033021 28.10527zM455.211458 849.516122h-251.791434c-7.839131 0-14.847386-3.215127-19.941015-8.453256-5.418754-4.624003-8.886757-11.307133-8.886756-19.579764v-252.47781c0-15.461511 12.535384-28.105271 28.10527-28.105271 15.461511 0 28.033021 12.643759 28.033021 28.105271l-0.903126 184.41826 190.37889-190.37889 39.665279 39.593029-190.74014 190.74014h186.007761c15.461511 0 28.033021 12.643759 28.033021 28.105271a27.97522 27.97522 0 0 1-27.960771 28.03302z",
                back: "path://M433 248a8 8 0 0 0-8-8h-48a8 8 0 0 0-8 8v120H248a8 8 0 0 0-8 8v48a8 8 0 0 0 8 8h152c0.167 0 0.334-0.001 0.5-0.004 0.166 0.003 0.333 0.004 0.5 0.004 17.673 0 32-14.327 32-32V248zM592 248a8 8 0 0 1 8-8h48a8 8 0 0 1 8 8v120h120a8 8 0 0 1 8 8v48a8 8 0 0 1-8 8H624a32.45 32.45 0 0 1-3.272-0.165C604.592 430.196 592 416.569 592 400V248zM432 776a8 8 0 0 1-8 8h-48a8 8 0 0 1-8-8V656H248a8 8 0 0 1-8-8v-48a8 8 0 0 1 8-8h152c1.105 0 2.196 0.056 3.272 0.165C419.408 593.804 432 607.431 432 624v152zM592 776a8 8 0 0 0 8 8h48a8 8 0 0 0 8-8V656h120a8 8 0 0 0 8-8v-48a8 8 0 0 0-8-8H624a32.45 32.45 0 0 0-3.272 0.165C604.592 593.804 592 607.431 592 624v152z",
            },
        },
        restore: {
            show: false,
            title: "重置",
            icon: "M960 416V192l-73.056 73.056a447.712 447.712 0 0 0-373.6-201.088C265.92 63.968 65.312 264.544 65.312 512S265.92 960.032 513.344 960.032a448.064 448.064 0 0 0 415.232-279.488 38.368 38.368 0 1 0-71.136-28.896 371.36 371.36 0 0 1-344.096 231.584C308.32 883.232 142.112 717.024 142.112 512S308.32 140.768 513.344 140.768c132.448 0 251.936 70.08 318.016 179.84L736 416h224z",
        },
        saveAsImage: {
            backgroundColor: "#021a6b",
            icon: "M490.45 757.79l0.36 0.34c0.25 0.24 0.5 0.48 0.75 0.71l0.07 0.06 0.51 0.44 0.6 0.51 0.12 0.1 0.59 0.46 0.51 0.39 0.17 0.13 0.63 0.44 0.46 0.32 0.22 0.15 0.64 0.4 0.46 0.29 0.25 0.15 0.62 0.34 0.5 0.28 0.26 0.14 0.58 0.29 0.57 0.28 0.25 0.12 0.54 0.23 0.67 0.29 0.23 0.1 0.49 0.18 0.77 0.29 0.19 0.07 0.45 0.14 0.86 0.28 0.16 0.05 0.42 0.11 0.94 0.25h0.13l0.41 0.09 1 0.21h0.11l0.45 0.08 1 0.16h0.1l0.54 0.06 0.9 0.11h0.08l0.76 0.05 0.72 0.05H514.69l0.77-0.06 0.7-0.05h0.09l0.93-0.11 0.51-0.06h0.1l1-0.17 0.42-0.07h0.11l1-0.22 0.39-0.09h0.13l1-0.26 0.4-0.11h0.15l0.89-0.29 0.44-0.14 0.18-0.07 0.79-0.3 0.49-0.19 0.21-0.09 0.67-0.29 0.56-0.24 0.23-0.11 0.56-0.28 0.62-0.31 0.23-0.13 0.47-0.27 0.67-0.38 0.22-0.14 0.42-0.26 0.71-0.45 0.19-0.13 0.39-0.28 0.73-0.51 0.14-0.11 0.43-0.33 0.71-0.55 0.09-0.08 0.56-0.48 0.59-0.51q0.58-0.53 1.13-1.08l188.95-190a32 32 0 0 0-45.38-45.13L544.86 657.64 544 126.93A32 32 0 0 0 512 95h-0.05A32 32 0 0 0 480 127l0.87 531.1L344 522.33a32 32 0 0 0-45.07 45.44z",
        },
    }
}
