/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IOptionNumberItem, IPageData, IPageParams } from "../types"

/**
 * 启停
 */
export enum E_MachineOpen {
    /**
     * 启用
     */
    Open =  1,
    /**
     * 停用
     */
    Close = 2,
}

/**
 * 设备类型
 * $t('enum.E_AlarmLampEquipmentType.AudioAndLight')
 * $t('enum.E_AlarmLampEquipmentType.Light')
 * $t('enum.E_AlarmLampEquipmentType.Audio')
 */
export enum E_AlarmLampEquipmentType {
    /**
     * 声光
     */
    AudioAndLight = 1,
    /**
     * 灯光
     */
    Light = 2,
    /**
     * 声音
     */
    Audio = 3,
}

export interface IMappingItem {
    areaId
    companyId
    equipmentId
    mappingId
    mappingName
    sensorId
    sensorType
}

export interface IMappingItemDetail extends IMappingItem {
    areaName: string
    companyName: string
    equipmentName: string
}

export interface IAlarmLampItemBase {
    /**
     * 报警等级
     */
    alarmLeavel: number
    /**
     * 报警时间
     */
    alarmTime: number
    /**
     * 区域Id
     */
    areaId: number
    /**
     * 公司Id
     */
    companyId: number
    /**
     * 设备地址
     */
    machineAddress: string
    /**
     * 设备公司
     */
    machineCompany: string
    /**
     * 设备id
     */
    machineId?: string
    /**
     * 设备ip
     */
    machineIp: string
    /**
     * 设备型号
     */
    machineModel: string
    /**
     * 设备名称
     */
    machineName: string
    /**
     * 设备端口
     */
    machinePort: string
    /**
     * 设备类型
     */
    machineType: E_AlarmLampEquipmentType
}

export interface IAlarmLampItem extends IAlarmLampItemBase {
    /**
     * 
     */
    mappingList: IMappingItem[]
}

export type IAddLampParams = IAlarmLampItem

export interface IClosePageItem {
    id: number
    companyName: string
    areaName: string
    machineName: string
}
export type IClosePageParams = IPageParams

export type IClosePageData = IPageData<IClosePageItem>

export interface ILampDeactivateParams {
    id: number
    machineOpen: E_MachineOpen
}

export interface ILampDetailParams {
    id: number
}

export interface ILampDetailData extends IAlarmLampItemBase {
    areaName: string
    companyName: string
    id: number
    mappingList: IMappingItemDetail[]
}

export type IIampLeaveListData = IOptionNumberItem[]
export type IIampTimeListData = IOptionNumberItem[]

export interface IIampPageListItem {
    id: number
    companyName: string
    areaName: string
    machineCompany: string
    machineModel: string
    machineName: string
    machineType: E_AlarmLampEquipmentType
    num: number
    machineOpen: E_MachineOpen
}

export type IIampPageListParams = IPageParams & Partial<{
    companyId: number
    areaId: number
    machineName: string
}>

export type IIampPageListData = IPageData<IIampPageListItem>

export interface ILampUpdateParams extends IAlarmLampItem {
    id: number
}