/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types"

/**
 * $t('enum.E_UpgradeStatus.Success')
 * $t('enum.E_UpgradeStatus.Failed')
 */
export enum E_UpgradeStatus {
    /**
     * 升级完成
     */
    Success = 1,
    /**
     * 升级失败
     */
    Failed = 2,
}


export interface IFirmwareUpgradItem {
    /**
     * 地址id
     */
    fileId: number
    /**
     * 大小
     */
    fileSize: string
    /**
     * 文件描述
     */
    firmwareDesc: string
    /**
     * 固件名称
     */
    firmwareName: string
    /**
     * 固件类型
     */
    firmwareType: string
    /**
     * 固件版本
     */
    firmwareVersion: string
}

export type IFirmwareUpgradAddParams = IFirmwareUpgradItem

export type IFirmwareUpgradPageListParams = IPageParams & Partial<{
    startTime
    firmwareName
    endTime
    typeList
}>


export interface IFirmwareUpgradPageListItem extends IFirmwareUpgradItem {
    id: number
    createTime: string
    fileUrl: string
}

export type IFirmwareUpgradPageListData = IPageData<IFirmwareUpgradPageListItem>

export interface IFirmwareUpgradActionParams {
    id: number
    sensorIds: string[]
}

export interface IFirmwareUpgradImportParams {
    file
}

export interface IFirmwareUpgradImportItem {
    sensorId: string
}

export type IFirmwareUpgradImportData = IFirmwareUpgradImportItem[]


export interface IFirmwareUpgradListFirmwareItem {
    id: number
    firmwareName: string
}

export type IFirmwareUpgradListFirmwareData = IFirmwareUpgradListFirmwareItem[]


export type IFirmwareUpgradRecordPageListParams = IPageParams & Partial<{
    startTime
    endTime
    sensorId
    upgradeStatus: E_UpgradeStatus
    firmwareVersion
}>

export interface IFirmwareUpgradRecordPageItem {
    id: number
    /**
     * 
     */
    createTime: string
    /**
     * 
     */
    firmwareName: string
    /**
     * 
     */
    firmwareType: string
    /**
     * 
     */
    firmwareVersion: string
    /**
     * 上次固件名称
     */
    lastFirmwareName: string
    /**
     * 上次固件类型
     */
    lastFirmwareType: string
    /**
     * 上次固件版本
     */
    lastFirmwareVersion: string
    /**
     * 
     */
    sensorId: string
    /**
     * 
     */
    upgradeStatus: E_UpgradeStatus
    /**
     * 升级时间
     */
    upgradeTime: string
}

export type IFirmwareUpgradRecordPageData = IPageData<IFirmwareUpgradRecordPageItem>
