/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "@/api/types"
import { InspectionLinePartialItemDetail } from "./inspection_line.types"
import { E_SensorKlass } from "@/api/moudules/sensor.types"

/**
 * $t('enum.E_InspectionYesNo.Yes')
 * $t('enum.E_InspectionYesNo.No')
 */
export enum E_InspectionYesNo {
    /**
     * 是
     */
    Yes = 1,
    /**
     * 否
     */
    No = 2,
}
/**
 * 巡检顺序类型
 * $t('enum.E_InspectType.ByOrder')
 * $t('enum.E_InspectType.Free')
 */
export enum E_InspectType {
    /**
     * 按序点检
     */
    ByOrder = 1,
    /**
     * 自由点检
     */
    Free = 2,
}

/**
 * 路线类型
 * $t('enum.E_LineType.Default')
 * $t('enum.E_LineType.Custom')
 */
export enum E_LineType {
    /**
     * 默认路线
     */
    Default = 1,
    /**
     * 自定义路线
     */
    Custom = 2,
}

/**
 * 计划状态
 * $t('enum.E_InspectionTaskStatus.Running')
 * $t('enum.E_InspectionTaskStatus.Finished')
 * $t('enum.E_InspectionTaskStatus.Timeout')
 * $t('enum.E_InspectionTaskStatus.NoStart')
 */
export enum E_InspectionTaskStatus {
    /**
     * 进行中
     */
    Running = 1,
    /**
     * 已完成
     */
    Finished = 2,
    /**
     * 已超时
     */
    Timeout = 3,
    /**
     * 未开始
     */
    NoStart = 4,
}

/**
 * 巡检状态
 * $t('enum.E_InspectStatus.HasInspected')
 * $t('enum.E_InspectStatus.NotInspect')
 * $t('enum.E_InspectStatus.PartialUpload')
 * $t('enum.E_InspectStatus.NotYetInTime')
 * $t('enum.E_InspectStatus.Timeout')
 * $t('enum.E_InspectStatus.SkipInspect')
 */
export enum E_InspectStatus {
    /**
     * 已点检
     */
    HasInspected = 1,
    /**
     * 未点检
     */
    NotInspect = 2,
    /**
     * 部分上传
     */
    PartialUpload = 3,
    /**
     * 未到时间
     */
    NotYetInTime = 4,
    /**
     * 超时
     */
    Timeout = 5,
    /**
     * 跳检
     */
    SkipInspect = 6,
}

export enum E_Color {
    Green = 1,
    Yellow = 2,
    Red = 3,
}

export interface IEquipmentItem {
    areaId: number
    companyId: number
    equipmentId: number
    inspectPointId: number
    /**
     * 是否巡检
     */
    isInspect: E_InspectionYesNo
    orderNum: number
    remark: string
    standardId: number
}

export interface IEquipmentItemDetail {
    areaName: string
    companyName: string
    equipmentId: number
    equipmentName: string
    id: number
    inspectPointId: number
    inspectTaskId: number
    standardId: number
    isInspect: E_InspectionYesNo
    remark: string
    orderNum: number
}

export interface IInspectionTaskItemCommon {
    areaId?: number
    companyId: number
    /**
     * 过期时间
     */
    expireTime?: string
    /**
     * 巡检班组id
     */
    groupId: number
    inspectType: E_InspectType
    /**
     * 巡检闹钟
     */
    inspectClock?: string
    /**
     * 巡检线路id
     */
    lineId: number
    /**
     * 路线类型
     */
    lineType: E_LineType
    /**
     * 巡检执行人id
     */
    operatorId: number
    remark: string
    /**
     * 任务名称
     */
    taskName: string
    /**
     * 计划开始时间
     */
    taskBeginTime: string
    /**
     * 计划结束时间
     */
    taskEndTime: string
}

export interface IInspectionTaskItem extends IInspectionTaskItemCommon {
    /**
     * 巡检设备
     */
    equipmentList: IEquipmentItem[]
}

export interface IInspectionTaskLineItem {
    id: number
    lineName: string
    pointList: InspectionLinePartialItemDetail[]
}
export type IInspectionTaskAllLineData = IInspectionTaskLineItem[]

export interface IInspectionTaskDetailData extends IInspectionTaskItemCommon {
    areaName: string
    companyName: string
    /**
     * 班组名称
     */
    groupName: string
    /**
     * 点检执行人
     */
    operatorName: string
    createdTime: string
    updatedtime: string
    taskStatus: E_InspectionTaskStatus
    /**
     * 巡检设备
     */
    equipmentList: IEquipmentItemDetail[]
    id: number
}

export type IInspectionTaskPageListParams = IPageParams & Partial<{
    companyId: number
    groupId: number
    id: number
    operatorId: number
    taskName: number
    taskStatus: E_InspectionTaskStatus
}>

export interface IInspectionTaskPageListItem {
    companyName: string
    /**
     * 异常数量
     */
    errorNum: number
    /**
     * 巡检班组
     */
    groupName: string
    id: number
    inspectNum: number
    lineName: string
    operatorName: string
    projectNum: number
    taskName: string
    taskStatus: E_InspectionTaskStatus
    taskbeginTime: string
    taskendTime: string
}

export type IInspectionTaskPageListData = IPageData<IInspectionTaskPageListItem>

export interface IInspectionTaskUpdateParams extends IInspectionTaskItem {
    id: number
}

export interface IInspectionTaskDeleteParams {
    ids: number[]
}


//#region 测点

export interface IInspectionMappingExportParams {
    equipmentId: number
    inspectTaskId: number
}

export interface IInspectionMappingEigenDataListParams {
    inspectTaskId: number
    mappingId: number
    chartType: number
}

export interface IInspectionMappingEigenDataItem {
    az: number
    bfZ: number
    id: number
    mappingId: number
    rotateSpeed: number
    waveId: number
    x: string
    y: number
    y1: number
    y2: number
    y3: number
}
export type IInspectionMappingEigenDataListData = IInspectionMappingEigenDataItem[]


export interface IInspectionMappingWaveParams {
    chartType: number
    waveId: number
}

export interface IWaveDataItem {
    x: number
    y1: number
    y2: number
    y3: number
}

export interface IInspectionMappingWaveData {
    interval: number
    waveData: IWaveDataItem[]
}

export type IInspectionMappingListParams = IInspectionMappingExportParams


export interface IInspectionMappingListItem {
    color: E_Color
    /**
     * 采样时间
     */
    collectTime: string
    id: number
    /**
     * 任务Id
     */
    inspectTaskId: number
    /**
     * 测点Id
     */
    mappingId: string
    /**
     * 设备Id
     */
    equipmentId: number
    /**
     * 测点名称
     */
    pointName: string
    /**
     * 振动烈度
     */
    vibrate: string
    /**
     * 上传时间
     */
    uploadTime: string
}
export interface IInspectionMappingListData {
    pageList: IInspectionMappingListItem[]
    taskName: string
    userName: string
}

export interface InspectionMappingWaveDetailsParams {
    inspectTaskId: number
    mappingId: number
}

export interface IWaveItem {
    createTime: string
    waveId: number
}

export interface InspectionMappingWaveDetailsData {
    areaName: string
    battery: number
    tem: number
    sensorId: string
    sensorType: string
    waveList: IWaveItem[]
}

export interface IInspectionMappingTaskMappingDetailParams {
    inspectTaskId: number
    mappingId: number
}

export interface IInspectionMappingTaskMappingDetailData {
    equipmentName: string
    img1File: string
    img2File: string
    img3File: string
    /**
     * 点检顺序
     */
    inspectType: E_InspectType
    /**
     * 设备类型
     */
    isoParam1: string
    /**
     * 支撑类型
     */
    isoParam2: string
    /**
     * ISO标准
     */
    isoType: string
    /**
     * 点检区域
     */
    mappingArea: string
    mappingId: number
    /**
     * 设备测点
     */
    mappingName: string
    /**
     * 录音1
     */
    recording1File: string
    /**
     * 录音2
     */
    recording2File: string
    remark: string
    sensorId: string
    /**
     * MAC地址
     */
    sensorMac: string
    /**
     * 传感器类型
     */
    sensorType: E_SensorKlass
    waveIds: number[]
}


export interface IInspectionMappingExportWaveParams {
    waveId: number
}

//#endregion

//#region 设备

export type IInspectionEquipmentPageListParams = IPageParams & {
    inspectTaskId: number
} & Partial<{
    startTime: string
    beginTime: string
    endTime: string
    equipmentName: string
    inspectStatus: E_InspectStatus
}>

export interface IInspectionEquipmentPageListItem {
    /**
     * 报警
     */
    alarmNum: number
    /**
     * 已测
     */
    alreadyNum: number
    areaName: string
    companyName: string
    /**
     * 危险
     */
    dangerNum: number
    equipmentId: number
    equipmentName: string
    id: number
    inspectStatus: E_InspectStatus

    /**
     * 是否点检
     */
    isInspect: E_InspectionYesNo

    /**
     * 任务id
     */
    inspectTaskId: number
    /**
     * 未测
     */
    noNum: number
    /**
     * 应测
     */
    shouldNum: number
    uploadTime: string
    /**
     * 备注
     */
    remark?: string
}

export type IInspectionEquipmentPageListData = IPageData<IInspectionEquipmentPageListItem>

//#endregion
