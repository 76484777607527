/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { IPageData, IPageParams } from "../types";


/**
 * $t('enum.E_LaunchState.Launched')
 * $t('enum.E_LaunchState.NotLaunched')
 */
export enum E_LaunchState {
    /**
     * 启动
     */
    Launched = 0,
    /**
     * 不启动
     */
    NotLaunched = 1,
}

/**
 * 模型部件
 * $t('enum.E_ModelPartial.Motor')
 * $t('enum.E_ModelPartial.Fans')
 * $t('enum.E_ModelPartial.WaterPump')
 * $t('enum.E_ModelPartial.Belt')
 * $t('enum.E_ModelPartial.AirPressure')
 * $t('enum.E_ModelPartial.Centrifugal')
 * $t('enum.E_ModelPartial.Cogs')
 * $t('enum.E_ModelPartial.Roller')
 * $t('enum.E_ModelPartial.Other')
 * $t('enum.E_ModelPartial.PickupPump')
 */
export enum E_ModelPartial {
    /**电机 */
    Motor = 1,
    /**风机 */
    Fans = 2,
    /**水泵 */
    WaterPump = 3,
    /**皮带 */
    Belt = 4,
    /**空压机 */
    AirPressure = 5,
    /**离心 */
    Centrifugal = 6,
    /**齿轮 */
    Cogs = 7,
    /**辊子 */
    Roller = 8,
    /**其它 */
    Other = 9,
    /**
     * 真空泵
     */
    PickupPump = 10,
}

export interface IModelPageListParams extends IPageParams {
    companyId?: number
    name?: string
}

export interface IModelPageItem {
    id: number
    createTime: string
    createBy: string
    updateTime: string
    updateBy: string
    delFlag: number
    /**
     * 故障诊断
     */
    need: E_LaunchState
    /**
     * 模型名称
     */
    name: string

    path: string
    /**
     * 设备类型ID
     */
    equipmentType: number
    /**
     * 模型类型名称
     */
    equipmentTypeName: string
    /**
     * 模型图片
     */
    basekey: number

    /**
     * 如果被设备使用了，模型有的地方不能修改删除
     */
    isUsed: boolean
}

export type IModelPageListData = IPageData<IModelPageItem>

export interface IModelCreateEditParams {
    id?: number
    companyId: number
    /**
     * 故障诊断
     */
    need: E_LaunchState
    /**
     * 模型名称
     */
    name: string
    /**
     * 
     */
    path?: string
    /**
     * 设备类型ID
     */
    equipmentTypeId: number
    /**
     * 模型图片Id
     */
    basekey?: number
    /**
     * 模型配置ID逗号隔开
     */
    modelConfig
}

export interface IModelConfigItem {
    code: number
    id: number
    name: string
    base64: string
}

export type IModelConfigListData = IModelConfigItem[]


export interface EquipmentType {
    companyId: number;
    createBy: null;
    createTime: null;
    delFlag: number;
    id: number;
    /**
     * 模型配置逗号隔开，1电机2齿轮箱3水泵4风机5皮带6辊子7离心机8空压机9其他
     */
    modelConfig: null;
    /**
     * 模型类型
     */
    name: string;
    updateBy: null;
    updateTime: null;
}

export interface IModelGetData {
    companyId: number
    basekey: number;
    createBy: null;
    createTime: null;
    delFlag: number;
    equipmentType: EquipmentType;
    equipmentTypeId: number;
    id: number;
    /**
     * 模型名称
     */
    name: string;
    modelConfig: string
    /**
     * 故障诊断0否1是
     */
    need: E_LaunchState;
    path: string;
    updateBy: null;
    updateTime: null;
    fileUrl: string
    isUsed: boolean
    modelConfigList: IModelConfigItem[]
}


export type IModelTypeGetByEquipmentTypeIdData = IModelConfigItem[]
