/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import _ from 'lodash'
import { E_ChartTypeString } from "@/types";

export const MultipleIndicatorsTrendConfig = [
    {
        name: 'ay1',
        displayName: 'aaaa',
        chartType: E_ChartTypeString.ACCELERATION,
    },
    {
        name: 'ay2',
        displayName: 'aaaa',
        chartType: E_ChartTypeString.ACCELERATION,
    },
    {
        name: 'ay3',
        displayName: 'aaaa',
        chartType: E_ChartTypeString.ACCELERATION,
    },
    {
        name: 'bfY1',
        displayName: 'bfY1',
        chartType: E_ChartTypeString.CREST_FACTOR,
    },
    {
        name: 'bfY2',
        displayName: 'bfY2',
        chartType: E_ChartTypeString.CREST_FACTOR,
    },
    {
        name: 'bfY3',
        displayName: 'bfY3',
        chartType: E_ChartTypeString.CREST_FACTOR,
    },
    {
        name: 'ey1',
        displayName: 'ey1',
        chartType: E_ChartTypeString.ENVELOPE_DEMODULATION,
    },
    {
        name: 'ey2',
        displayName: 'ey2',
        chartType: E_ChartTypeString.ENVELOPE_DEMODULATION,
    },
    {
        name: 'ey3',
        displayName: 'ey3',
        chartType: E_ChartTypeString.ENVELOPE_DEMODULATION,
    },
    {
        name: 'qy1',
        displayName: 'qy1',
        chartType: E_ChartTypeString.KURTOSIS,
    },

    {
        name: 'qy2',
        displayName: 'qy2',
        chartType: E_ChartTypeString.KURTOSIS,
    },
    {
        name: 'qy3',
        displayName: 'qy3',
        chartType: E_ChartTypeString.KURTOSIS,
    },
    {
        name: 'rSpeed',
        displayName: 'rSpeed',
        chartType: E_ChartTypeString.SPEED,
        yAxisIndex: 1,
    },
    {
        name: 'sy1',
        displayName: 'sy1',
        chartType: E_ChartTypeString.DISPLACEMENT,
    },
    {
        name: 'sy2',
        displayName: 'sy2',
        chartType: E_ChartTypeString.DISPLACEMENT,
    },
    {
        name: 'sy3',
        displayName: 'sy3',
        chartType: E_ChartTypeString.DISPLACEMENT,
    },
    {
        name: 'tem',
        displayName: 'tem',
        chartType: E_ChartTypeString.TEMPERATURE,
        yAxisIndex: 1,
    },
    {
        name: 'vy1',
        displayName: 'vy1',
        chartType: E_ChartTypeString.VIBRATIO_INNTENSITY,
    },
    {
        name: 'vy2',
        displayName: 'vy2',
        chartType: E_ChartTypeString.VIBRATIO_INNTENSITY,
    },

    {
        name: 'vy3',
        displayName: 'vy3',
        chartType: E_ChartTypeString.VIBRATIO_INNTENSITY,
    },
]

export const MultipleIndicatorsTrendDimensions = [{
    name: 'x',
    displayName: 'x',
}, ..._.map(MultipleIndicatorsTrendConfig, c => {
    return {
        name: c.name,
        // displayName: c.displayName,
    }
})]

export function buildSeries(vm: Vue, charts: E_ChartTypeString[]) {
    const chartSet = new Set(charts)

    return _.flatMap(MultipleIndicatorsTrendConfig, config => {
        if (!chartSet.has(config.chartType)) {
            return []
        }

        return [{
            type: 'line',
            name: vm.$enumTrans.ChartTypeString(config.chartType),
            encode: {
                x: 'x',
                y: config.name,
            },
            yAxisIndex: _.defaultTo(config.yAxisIndex, 0),
            connectNulls: true,
        }]
    })
}
