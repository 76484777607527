/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

export function contentFileName(header: string = '') {
    const idx = header.search('filename=')
    if (idx >= 0) {
        return decodeURIComponent(header.substring(idx + 'filename='.length))
    }
    return header
}

export function download(data, filename) {
    const blob = new Blob([data], { type: 'application/octet-stream' })

    // 创建新的URL并指向File对象或者Blob对象的地址
    const blobURL = window.URL.createObjectURL(blob)
    downloadURL(blobURL, filename)
    // 释放blob URL地址
    window.URL.revokeObjectURL(blobURL)
}

export function downloadURL(fileurl, filename?) {
    // 创建a标签，用于跳转至下载链接
    const tempLink = document.createElement('a')
    tempLink.style.display = 'none'
    tempLink.href = fileurl
    if (filename) {
        tempLink.setAttribute('download', decodeURI(filename))
    }
    // 兼容：某些浏览器不支持HTML5的download属性
    if (typeof tempLink.download === 'undefined') {
        tempLink.setAttribute('target', '_blank')
    }
    // 挂载a标签
    document.body.appendChild(tempLink)
    tempLink.click()
    document.body.removeChild(tempLink)
}
