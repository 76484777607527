/**
 * @Copyright(c) 2017-2024 捷杰传感 
 * @Author（创建人） gujiandong
 * @Date（创建时间） 2024/5/10 14:28
 * @Description（类描述）: 
 * @Version（版本）: v1.0
 * @DemandAddress（需求地址）：
 */

import { E_ChartTypeString, E_Eigenvalue } from "@/types";

export const ChartTypeOptions = Object.freeze([
    E_ChartTypeString.ACCELERATION_TIME,
    E_ChartTypeString.ACCELERATION_FREQUENCY,
    E_ChartTypeString.VELOCITY_TIME,
    E_ChartTypeString.VELOCITY_FREQUENCY,
    E_ChartTypeString.ENVELOPE_SIGNAL,
    E_ChartTypeString.ENVELOPE_FREQ,
    //   
    E_ChartTypeString.ACCELERATION,
    E_ChartTypeString.VIBRATIO_INNTENSITY,
    E_ChartTypeString.DISPLACEMENT,
    E_ChartTypeString.TEMPERATURE,
    E_ChartTypeString.TEMPERATURE_RISE,
    E_ChartTypeString.KURTOSIS,
    E_ChartTypeString.CREST_FACTOR,
    E_ChartTypeString.ENVELOPE_DEMODULATION,
    E_ChartTypeString.SPEED,
    E_ChartTypeString.ANGLE,
    E_ChartTypeString.BATTERY,
])

export const ChartTypeString2Eigenvalue = {
    [E_ChartTypeString.ACCELERATION_TIME]: E_Eigenvalue.AccelerationTimeDomain,
    [E_ChartTypeString.ACCELERATION_FREQUENCY]: E_Eigenvalue.AccelerationFrequencyDomain,
    [E_ChartTypeString.VELOCITY_TIME]: E_Eigenvalue.VelocityDomain,
    [E_ChartTypeString.VELOCITY_FREQUENCY]: E_Eigenvalue.VelocityFrequencyDomain,
    [E_ChartTypeString.ENVELOPE_SIGNAL]: E_Eigenvalue.EnvelopeSpectrum,
    [E_ChartTypeString.ENVELOPE_FREQ]: E_Eigenvalue.EnvelopeSignal,
    [E_ChartTypeString.DISPLACEMENT]: E_Eigenvalue.Offset,
    [E_ChartTypeString.DISPLACEMENT_FREQUENCY]: E_Eigenvalue.DisplacementSpectrum,    
}
